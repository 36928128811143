import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  current_title = "";
  current_image_url = "";
  constructor(private title: Title, private meta: Meta) { }


  // updateTitle(title: string) {
  //   this.title.setTitle(title);
  // }
  private logView() {
    if(window.PARSELY.beacon) {
      var url = location.href;
      var urlref = location.href;
      // console.log("LOG " + url)
      // var title = this.meta.getTag("parsely-title");
      // var image_url = this.meta.getTag("parsely-image-url");


      window.PARSELY.beacon.trackPageView({
          url: url,
          urlref: urlref,
          title: this.current_title,
          image_url: this.current_image_url,
          js: 1
      });
      return true;
    } else {
      // console.log("trying again")
      setTimeout(this.logView, 1000);
    }
  }

  updateParselyTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'parsely-title', content: title })
    this.current_title = title;
  }
  updateParselyLink(link: string) {
    this.meta.updateTag({ name: 'parsely-link', content: link })
  }
  updateParselyImageUrl(imageUrl: string) {
    this.meta.updateTag({ name: 'parsely-image-url', content: imageUrl })
    this.current_image_url = imageUrl;
    this.logView();
  }
}
