import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Http, ResponseContentType} from '@angular/http';
import * as moment from "moment";
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  root_url:string = (location.origin.indexOf("localhost") === -1) ? location.origin + '/api' : 'http://localhost:80/api';

  constructor(private http: HttpClient) {
  }

  public getToken() {
    return localStorage.getItem('id_token');
  }

  login(email:string, password:string ) {
    return this.http.post<any[]>(this.root_url + '/login', {email, password})
      .pipe(tap(res => this.setSession(res)));
  }

  signup(name_first:string, name_last:string, email:string, organization:string,  city:string, state:string, is_nspp:string, not_sure:string) {
    return this.http.post<any[]>(this.root_url + '/signup', {name_first, name_last, email, organization, city, state, is_nspp, not_sure})
      //.pipe(tap(res => this.setSession(res)));
  }

  

  postAccess(email:string) {
    return this.http.post<any[]>(this.root_url + '/access', {email})
  }

  postAccessVerify(user:string, id:string) {
    return this.http.post<any[]>(this.root_url + '/access-verify', {user, id})
    .pipe(tap(res => this.setSession(res)));
  }

  forgotPassword(email:string) {
    return this.http.post<any[]>(this.root_url + '/forgot', {email})
  }

  verifyResetCode(code:string) {
    return this.http.post<any[]>(this.root_url + '/reset-verify', {code})
  }

  resetPassword(newPassword:string, code:string) {
    return this.http.post<any[]>(this.root_url + '/reset-password', {newPassword, code})
  }

  saveProfile(args) {
    return this.http.post<any[]>(this.root_url + '/profile', args)
  }
  saveInvite(args) {
    return this.http.post<any[]>(this.root_url + '/invite', args)
  }

  // programs
  saveEvent(args) {
    return this.http.post<any[]>(this.root_url + '/event', args)
  }
  saveSocial(args) {
    return this.http.post<any[]>(this.root_url + '/social', args)
  }
  saveFeedback(args) {
    return this.http.post<any[]>(this.root_url + '/feedback', args)
  }

  getFile(args, isSecure = true) {
    if(isSecure) {
      return this.http.post<Blob>(this.root_url + '/getFileS', args, { responseType: 'blob' as 'json' });
    } else {
      return this.http.post<Blob>(this.root_url + '/getFile', args, { responseType: 'blob' as 'json' })
    }
  }

  getHomeStatus() {
    return this.http.get<any[]>(this.root_url + '/home')
  }

  getGeo() {
    //let url = this.root_url + '/geo';
    let url = "https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location";
    return this.http.get<any[]>(url)
  }
  getMetadata(args) {
    return this.http.post<any[]>(this.root_url + '/metadata', args)
  }

  getAllPrograms() {
    return this.http.get<any[]>(this.root_url + '/general')
  }

  getFeaturedPrograms() {
    return this.http.get<any[]>(this.root_url + '/featured')
  }

  getCalendarEvent(id) {
    return this.http.get<any[]>(this.root_url + '/calendarEvent/'+id)
  }
  postCalendarEvent(args) {
    return this.http.post<any[]>(this.root_url + '/calendarEvent', args)
  }
  
  postNogginRequest(args) {
    return this.http.post<any[]>(this.root_url + '/nogginRequest', args)
  }

  
  postNogginOptIn(args) {
    return this.http.post<any[]>(this.root_url + '/nogginOptIn', args)
  }

  // survey
  postSurveyVerifyStatus(args) {
    return this.http.post<any[]>(this.root_url + '/surveyVerifyStatus', args)
  }
  postSurveySubmit(args) {
    return this.http.post<any[]>(this.root_url + '/surveySubmit', args)
  }

  // admin
  getAdminOverview(args) {
    return this.http.get<any[]>(this.root_url + '/admin/')
  }
  getAdminUsers(args) {
    return this.http.post<any[]>(this.root_url + '/admin/users', args)
  }
  getAdminUserGroup(args) {
    return this.http.post<any[]>(this.root_url + '/admin/userGroup', args)
  }

  getAdminEblastDetails(id) {
    return this.http.get<any[]>(this.root_url + '/admin/eblastDetails/' + id)
  }
  exportEblast(id) {

  }
  postAdminEblast(args) {
    return this.http.post<any[]>(this.root_url + '/admin/eblast', args)
  }
  postAdminEblastSend(args) {
    return this.http.post<any[]>(this.root_url + '/admin/eblastSend', args)
  }
  postAdminEblastSendAll(args) {
    return this.http.post<any[]>(this.root_url + '/admin/eblastSendAll', args)
  }
  postAdminEblastAddRecipient(args) {
    return this.http.post<any[]>(this.root_url + '/admin/eblastAddRecipient', args)
  }
  postAdminEblastEditRecipient(args) {
    return this.http.post<any[]>(this.root_url + '/admin/eblastEditRecipient', args)
  }

  getAdminRsvpDetails(id) {
    return this.http.get<any[]>(this.root_url + '/admin/rsvpDetails/' + id)
  }

  getAdminSurveyDetails(id) {
    return this.http.get<any[]>(this.root_url + '/admin/surveyDetails/' + id)
  }  
  exportSurvey(id) {
    return this.http.get<Blob>(this.root_url + '/admin/exportSurvey/' + id, {responseType: 'blob' as 'json'})
  }
  postAdminSurveySendInvitation(args) {
    return this.http.post<any[]>(this.root_url + '/admin/surveySendInvitation', args)
  }

  getAdminSchoolOverview(args) {
    return this.http.get<any[]>(this.root_url + '/admin/school')
  }
  getAdminSchoolExtended(id) {
    return this.http.get<any[]>(this.root_url + '/admin/school/' + id)
  }
  getAdminProgramDetails(id) {
    return this.http.get<any[]>(this.root_url + '/admin/program/' + id)
  }

  getAdminNogginOverview(args) {
    return this.http.get<any[]>(this.root_url + '/admin/noggin')
  }

  postAdminNogginApproval(id, type, is_approved, unique_url_id) {
    return this.http.post<any[]>(this.root_url + '/admin/nogginApproval', { id: id, type: type, is_approved: is_approved, unique_url_id: unique_url_id})
  }

  postAdminSchoolApproval(args) {
    return this.http.post<any[]>(this.root_url + '/admin/schoolApproval', args)
  }

  postAdminSchoolInvite(id) {
    return this.http.post<any[]>(this.root_url + '/admin/schoolInvite', { id: id })
  }

  postMessage(message, reference_table, reference_id) {
    const args = {
      message: message,
      reference_table: reference_table,
      reference_id: reference_id
    };
    return this.http.post<any[]>(this.root_url + '/message', args)

  }

  getMessage(reference_table, reference_id) {
    return this.http.get<any[]>(this.root_url + '/message/' + reference_table + '/' + reference_id)
  }

  postEblast(args) {
    return this.http.post<any[]>(this.root_url + '/admin/eblast', args)
  }

  getAdminSocial(id) {
    return this.http.post<any[]>(this.root_url + '/admin/social', {id: id})
  }

  getAdminFeedback(id) {
    return this.http.post<any[]>(this.root_url + '/admin/feedback', {id: id})
  }

  getAdminForm(id) {
    return this.http.post<any[]>(this.root_url + '/admin/form', {id: id})
  }
  getAdminChat(id) {
    return this.http.post<any[]>(this.root_url + '/admin/chat', {id: id})
  }
  postAdminChat(id, msg) {
    return this.http.post<any[]>(this.root_url + '/admin/chatSend', {id: id, msg: msg})
  }

  exportEvents() {
    return this.http.get<Blob>(this.root_url + '/admin/exportEvents', {responseType: 'blob' as 'json'})
  }
  exportSchools() {
    return this.http.get<Blob>(this.root_url + '/admin/exportSchools', {responseType: 'blob' as 'json'})
  }
  exportEblastRecipients(id) {
    return this.http.get<Blob>(this.root_url + '/admin/exportEblastRecipients/'+id, {responseType: 'blob' as 'json'})
  }

  archiveItem(id, table) {
    return this.http.post<any[]>(this.root_url + '/admin/archiveItem', {id: id, table: table})
  }

  restoreItem(id, table) {
    return this.http.post<any[]>(this.root_url + '/admin/restoreItem', {id: id, table: table})
  }

  approveRegistration(id) {
    return this.http.post<any[]>(this.root_url + '/admin/approveRegistration', {id: id})
  }

  mainPartnerInvite(args) {
    return this.http.post<any[]>(this.root_url + '/admin/mainPartnerInvite', args)
  }

  postSurveySendInvitation(args) {
    return this.http.post<any[]>(this.root_url + '/admin/surveySendInvitation', args)
  }

  private setSession(authResult) {
    console.log("setting session");
    console.log(authResult);
    const expires_at = moment().add(30,'day');

    localStorage.setItem('id_token', authResult.id_token);
    localStorage.setItem("expires_at", JSON.stringify(expires_at.valueOf()) );
    localStorage.setItem("permission", authResult.permission );
  } 

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("permission");
  }

  public isLoggedIn() {
      if(localStorage.getItem("expires_at")) {
      var exp = this.getExpiration();
      var diff = moment().diff(exp, 'minutes');
      var isBefore = moment().isBefore(exp);
      //console.log(diff);
      //console.log(isBefore)
      return moment().isBefore(exp);
      } else {
        return false;
      }
  }

  isLoggedOut() {
      return !this.isLoggedIn();
  }

  getPermission() {
    const permission = localStorage.getItem("permission");
    return permission;

  }

  getExpiration() {
      const expiration = localStorage.getItem("expires_at");
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
  }

  /////////////////////

  getAccount() {
    return this.http.get(this.root_url + '/private/account')
  }

  getProgram(name) {
    return this.http.get(this.root_url + '/program/'+ name)
  }

  getZoomMeeting() {
    return this.http.get(this.root_url + '/zoomMeeting')
  }
  getZoomSignature(meetingNumber) {
    var data = {"meetingNumber": meetingNumber};
    return this.http.post(this.root_url + '/zoomSignature', data)
  }

  getNogginStatus() {
    return this.http.get(this.root_url + '/nogginStatus')

  }

  getSchoolStatus() {
    return this.http.get(this.root_url + '/schoolStatus')
  }

  getSchoolInvite(code) {
    return this.http.get(this.root_url + '/schoolVerifyInvitation/'+code)
  }

  getSchoolPartnerInvitation(code) {
    return this.http.get(this.root_url + '/schoolPartnerVerifyInvitation/'+code)
  }

  postSchoolRequest(args) {
    return this.http.post<any[]>(this.root_url + '/schoolRequest', args)
  }

  postSchoolPartnerRequest(args) {
    return this.http.post<any[]>(this.root_url + '/schoolPartnerRequest', args)
  }

  postSubmitForm(args) {
    return this.http.post<any[]>(this.root_url + '/submitForm', args)
  }

  ///////////////////

  deleteEvent(id) {
    return this.http.delete(this.root_url + '/event/' + id)
  }

}
