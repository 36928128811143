import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProgramComponent } from './program/program.component';

import { BluePsaComponent } from "./program/blue-psa/blue-psa.component";
import { KidoftheyearComponent } from "./program/kidoftheyear/kidoftheyear.component";

import { HomeComponent } from './home/home.component';
import { SurveyComponent } from './survey/survey.component';
import { FeaturedComponent } from './featured/featured.component';

import { HelpComponent } from './help/help.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AccessComponent } from './access/access.component';
import { VerifyComponent } from './verify/verify.component';
import { SchoolInviteComponent } from './program/school-invite/school-invite.component';

import { CalendarEventComponent } from "./calendar-event/calendar-event.component";
import { ProgramGenericComponent } from './program-generic/program-generic.component';
import { NsppRegisterComponent } from './program/nspp-register/nspp-register.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {animation: 'HomePage'} },
  { path: 'account', component: AccountComponent, data: {animation: 'AccountPage'} },
  { path: 'account/:tab', component: AccountComponent, data: {animation: 'AccountPage'} },
  { path: 'admin', component: AdminComponent, data: {animation: 'AdminPage'} },
  { path: 'login', component: LoginComponent, data: {animation: 'LoginPage'} },
  { path: 'signup', component: SignupComponent, data: {animation: 'SignupPage'} },
  { path: 'featured', component: FeaturedComponent, data: {animation: 'ProgramPage'} },
  { path: 'rsvp/:event_slug', component: CalendarEventComponent, data: {animation: 'EventPage'} },
  { path: 'survey/:survey_id', component: SurveyComponent, data: {animation: 'ProgramPage'} },
  { path: 'survey/:survey_id/:survey_code', component: SurveyComponent, data: {animation: 'ProgramPage'} },
  { path: 'program/:id', component: ProgramComponent, data: {animation: 'ProgramPage'} },
  { path: 'sis', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'sis'} },
  { path: 'noggin', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'noggin'} },
  { path: 'noggin/:key', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'noggin'} },
  { path: 'schoolpartnership', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'schoolpartnership'} },
  { path: 'schoolpartnership-registration', component: NsppRegisterComponent, data: {animation: 'ProgramPage', id: 'schoolpartnership'} },
  { path: 'schoolpartnership/:key', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'schoolpartnership'} },
  { path: 'school-invite/:key', component: SchoolInviteComponent, data: {animation: 'ProgramPage', id: 'schoolpartnership'} },

  
  { path: 'nicknews', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'nicknews'} },
  { path: 'black_history', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'black_history'} },
  { path: 'talentforgood', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'talentforgood'} },
  { path: 'scienceofslime', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'scienceofslime'} }, 

  { path: 'help', component: HelpComponent, data: {animation: 'HelpPage'} },
  { path: 'help/:topic', component: HelpComponent, data: {animation: 'HelpPage'} },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: {animation: 'ForgotPage'} },
  { path: 'reset-password/:id', component: ResetPasswordComponent, data: {animation: 'ResetPage', is_invite: false} },
  { path: 'invitation/:id', component: ResetPasswordComponent, data: {animation: 'ResetPage', is_invite: true} }, 
  
  { path: 'access', component: AccessComponent, data: {animation: 'AccessPage' } }, 
  { path: 'verify/:user_hash/:id_hash', component: VerifyComponent, data: {animation: 'VerifyPage' } },

  { path: ':program', component: ProgramGenericComponent, data: {animation: 'ProgramPage' } },
  { path: ':program/:id', component: ProgramGenericComponent, data: {animation: 'ProgramPage' } },

/*
  { path: 'savethemusic', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'savethemusic'} }, 
  { path: 'partner_school_highlights', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'partner_school_highlights'} },
  { path: 'grandparents_day', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'grandparents_day'} },
  { path: 'thanksgiving', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'thanksgiving'} },
  { path: 'holiday', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'holiday'} },
  { path: 'valentinesday', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'valentinesday'} },
  { path: 'easter', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'easter'} },
  { path: 'rjfreadingchallenge', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'rjfreadingchallenge'} },
  { path: '13thamendment', component: ProgramComponent, data: {animation: 'ProgramPage', id: '13thamendment'} },
  { path: 'KOTY2021', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'KOTY2021'} },
  { path: 'koty2021', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'KOTY2021'} },
  { path: 'rhymesthroughtimes', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'rhymesthroughtimes'} },
  { path: 'warpedmadlibs', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'warpedmadlibs'} },
  { path: 'koty', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'koty'} },
  { path: 'nickhelps', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'nickhelps'} },
  
  { path: 'pride', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'pride'} },
  { path: 'asian', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'asian'} },
  { path: 'jewish', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'jewish'} },
  { path: 'autism', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'autism'} },
  { path: 'gun_violence', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'gun_violence'} },
  { path: 'motivatinglearners', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'motivatinglearners'} },
  { path: 'hispanic_heritage', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'hispanic_heritage'} },
  { path: 'halloween', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'halloween'} },
  { path: 'hanukkah', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'hanukkah'} },
  { path: 'kpp', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'kpp'} },
  { path: 'visionboardforamerica', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'visionboardforamerica'} },
  { path: 'socialemotionallearning', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'socialemotionallearning'} },
  
  { path: 'nflslimetime', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'nflslimetime'} },
  { path: 'nickstands', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'nickstands'} },

  { path: 'blue_psa', component: BluePsaComponent, data: {animation: 'ProgramPage', id: 'blue_psa'} },
  { path: 'blue_psa/:page', component: BluePsaComponent, data: {animation: 'ProgramPage', id: 'blue_psa'} },

  { path: 'spongebob_science', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'spongebob_science'} },
  { path: 'black_history', component: ProgramComponent, data: {animation: 'ProgramPage', id: 'black_history'} },
*/

]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
