import { Component, OnInit, Inject, Input, Renderer } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// import { ZoomMtg } from "@zoomus/websdk";

import { AuthService } from "../../auth/auth.service";

// ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib', '/av');
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();

@Component({
  selector: 'app-kidoftheyear',
  templateUrl: './kidoftheyear.component.html',
  styleUrls: ['./kidoftheyear.component.scss']
})
export class KidoftheyearComponent implements OnInit {

  meeting = {
    status: null,
    meetingNumber: null,
    role: null,
    apiKey: null,
    leaveUrl: null,
    userEmail: null,
    passWord: null,
    timeDiff: null
  }

  watchedLive = false;

  refreshInterval = null;

  meetingStatus = null;
  meetingMessage = null;

  userName = null;
  viewSettings = false;
  embed = false;
  hideChat = true;
  callStarted = false;

  constructor(
    public authService: AuthService,
    private renderer: Renderer,
    @Inject(DOCUMENT) document
  ) { }

  ngOnInit() {
    localStorage.clear();
    this.renderer.setElementClass(document.body, 'theme-kidoftheyear', true);
    this.getMeeting();
    this.refreshInterval = setInterval(() => {
      this.getMeeting();
    }, 10000)
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-kidoftheyear', false);
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  getMeeting() {
    this.authService.getZoomMeeting().subscribe(
      (data: any) => {
        console.log(data)
        this.meeting = data.meeting;
        this.meetingMessage = data.message;
        this.meetingStatus = data.status;

        if(this.meetingStatus == "live") {
          this.watchedLive = true;
        }

        if(this.meetingStatus != "live" && this.watchedLive == true) {
          // refresh this page
          location.reload();
        }
      }
    )
  }

  getSignature() {
    if(this.meeting.meetingNumber) {
      this.authService.getZoomSignature(this.meeting.meetingNumber).subscribe(
        (data: any) => {
          if(data.signature) {
            this.startMeeting(data.signature)
          } else {
            console.log(data)
          }
        }
      )
    } else {
      alert("There are no meetings to join.")
    }
  }

  startMeeting(signature) {

    // Nick School Partnership Program Logo.svg
    // var logo = document.createElement("img");
    // logo.src = "/assets/Nick School Partnership Program Logo.svg";
    // logo.id = "floatingLogo";


    document.getElementById('zmmtg-root').style.display = 'block'
    // document.body.append(logo);
    document.getElementsByTagName("html")[0].style.overflow = "hidden"
    document.getElementsByTagName("body")[0].style.overflow = "hidden"
    document.getElementsByTagName("html")[0].style.height = "100%"
    var navbar = document.getElementsByClassName("navbar")
    if(navbar[0]) {
      (navbar[0] as HTMLElement).style.zIndex = "0";
    }

    this.callStarted = true;
    console.log("init")

    
    // ZoomMtg.init({
    //   leaveUrl: this.meeting.leaveUrl,
    //   isSupportAV: true,
    //   success: (success) => {
    //     console.log("success")
    //     console.log(success)

    //     ZoomMtg.join({
    //       signature: signature,
    //       meetingNumber: this.meeting.meetingNumber,
    //       userName: this.userName,
    //       apiKey: this.meeting.apiKey,
    //       userEmail: this.meeting.userEmail,
    //       passWord: this.meeting.passWord,
    //       success: (success) => {
    //         console.log("success")
    //         console.log(success)

    //         if(this.hideChat) {
    //           var a = document.getElementsByClassName("footer-button__chat-icon") as HTMLCollectionOf<HTMLElement>;
    //           var el = a[0].parentNode.parentNode as HTMLElement;
    //           el.style.display = "none"
    //           document.getElementById("site-navigation").style.display = "none";
    //         }

    //         // var logo = document.getElementById("floating-logo") as HTMLElement;
    //         // logo.className = "show navbar-brand py-2 px-4";
            
    //       },
    //       error: (error) => {
    //         console.log("error")
    //         console.log(error)
    //       }
    //     })

    //   },
    //   error: (error) => {
    //     console.log("error")
    //     console.log(error)
    //   }
    // })
  }

}
