import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { AppToastService } from '../_helpers/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  form:FormGroup;
  loading:boolean = false;
  didReset:boolean = false;

  constructor(
    private router: Router,
    private toastService:AppToastService,
    private fb:FormBuilder, 
    public authService: AuthService, 
    private renderer: Renderer) {
      this.renderer.setElementClass(document.body, 'theme-evergreen', true);

      this.form = this.fb.group({
        email: ['',Validators.required]
      });
  }

  ngOnInit() {
    if(this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/account');
    }
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }

  submitForm() {
    const val = this.form.value;
    if(val.email) {
      this.loading = true;
      this.authService.forgotPassword(val.email)
      .subscribe(
          () => {
            this.didReset = true;
              this.form.reset();
              this.loading = false;
              this.toastService.show("Sent!","Please check your email to reset your password");
              //this.router.navigateByUrl('/');
          },
          () => {
            this.form.reset();
            this.toastService.show("Error","Invalid Request");
            this.loading = false;
          }
      );
    }
  }

}