import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
//import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { ProgramComponent } from './program/program.component';
import { AccountComponent } from './account/account.component';
import { HomeComponent } from './home/home.component';

import { TokenInterceptor } from './_helpers/token.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { NgbdModalContent } from './_helpers/modal.component';
import { SignupComponent } from './signup/signup.component';
import { HelpComponent } from './help/help.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AppToastService } from './_helpers/toast.service';
import { VerifyComponent } from './verify/verify.component';
import { AccessComponent } from './access/access.component';

import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material'  
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FilterColumnPipe } from './filter-column.pipe';
import { SurveyComponent } from './survey/survey.component';
import { FeaturedComponent } from './featured/featured.component';
import { BluePsaComponent } from './program/blue-psa/blue-psa.component';
import { KidoftheyearComponent } from './program/kidoftheyear/kidoftheyear.component';
import { SchoolInviteComponent } from './program/school-invite/school-invite.component';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { ProgramGenericComponent } from './program-generic/program-generic.component';
import { NsppRegisterComponent } from './program/nspp-register/nspp-register.component';
import { NsppStayInTouchComponent } from './program/nspp-stay-in-touch/nspp-stay-in-touch.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    ProgramComponent,
    AccountComponent,
    HomeComponent,
    SignupComponent,
    HelpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NgbdModalContent,
    VerifyComponent,
    AccessComponent,
    FilterColumnPipe,
    SurveyComponent,
    FeaturedComponent,
    BluePsaComponent,
    KidoftheyearComponent,
    SchoolInviteComponent,
    CalendarEventComponent,
    ProgramGenericComponent,
    NsppRegisterComponent,
    NsppStayInTouchComponent,
  ],
  entryComponents: [NgbdModalContent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    AngularFontAwesomeModule,
    TableVirtualScrollModule,
    ScrollingModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
