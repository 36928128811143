import { Component, OnDestroy, Renderer, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnDestroy {

  formFeedback:FormGroup;
  loading:boolean;
  subjectList;

  constructor(
    private cd: ChangeDetectorRef,
    private renderer: Renderer,
    private fb:FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService) {
      this.renderer.setElementClass(document.body, 'theme-evergreen', true);
      
      var defaultSubject = 'General Feedback';
      var topic = this.route.snapshot.paramMap.get("topic");
      if(topic) {
        if(topic == "promoreview") {
          defaultSubject = "Promotion Review";
        }
        if(topic == "schoolpartnership") {
          defaultSubject = "School Partnership Program Feedback";
        }
      }

      this.formFeedback = this.fb.group({
        subject: [defaultSubject,Validators.required],
        body: ['',Validators.required],
        photo1: [null],
        photo2: [null],
        photo3: [null],
        photo4: [null],
        has_permission: false
      })

      this.subjectList = ["General Feedback", "Account Feedback", "WWDOP Feedback", "School Partnership Program Feedback",  "Website Feedback", "Promotion Review"];
  }
  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }

  submitFeedback() {
    this.loading = true;
    const val = this.formFeedback.value;
    this.authService.saveFeedback(val)
        .subscribe(
            () => {
              this.loading = false;
              this.formFeedback.reset();
              alert("Thank you for submitting your feedback! If you submit a question we'll get back to you soon.");
              //this.showEventForm = false;
              //this.getProgram();
            },
            (err) => {
              this.loading = false;
              alert(err);
            }
        );
  }

  onFileChange(event, form:FormGroup, field) {
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const extension = file.name.split('.')[1].toLowerCase();
      const valid_extensions = ['png', 'jpg', 'jpeg'];
      if(valid_extensions.includes(extension)) {
        reader.readAsDataURL(file);
    
        reader.onload = () => {
          form.patchValue({
            [field]: reader.result
          });
          this.cd.markForCheck();
        };
      } else {
        form.patchValue({
          [field]: null
        });
      }
    }
  }

}
