import {trigger, animate, style, group, query, transition} from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width:'100%' }), { optional: true }),

    group([
      query(':enter', [
        style({ opacity: '0', transform: 'translateY(-30px)' }),
        animate('0.5s ease-in-out', style({ opacity: '1', transform: 'translateY(0vh)' }))
      ], { optional: true }),
      query(':leave', [
        style({ opacity: '1', transform: 'translateY(0vw)' }),
        animate('0.3s ease-in-out', style({ opacity: '0', transform: 'scale(0)' }))
      ], { optional: true }),
    ])
    
    
  ])
])