import { Component, OnInit } from '@angular/core';
import { MetadataService } from 'src/app/_helpers/metadata.service';

@Component({
  selector: 'app-nspp-stay-in-touch',
  templateUrl: './nspp-stay-in-touch.component.html',
  styleUrls: ['./nspp-stay-in-touch.component.scss']
})
export class NsppStayInTouchComponent implements OnInit {

  constructor(
    public metadataService: MetadataService) { }

  ngOnInit() {
    this.metadataService.updateParselyTitle("Stay In Touch - Nickelodeon Community Partner Resources");
    this.metadataService.updateParselyLink(window.location.href);
    this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/Nickelodeon_CPR_logo_white.svg");
  }

}
