import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AppToastService } from 'src/app/_helpers/toast.service';
import { MetadataService } from 'src/app/_helpers/metadata.service';

@Component({
  selector: 'app-nspp-register',
  templateUrl: './nspp-register.component.html',
  styleUrls: ['./nspp-register.component.scss']
})
export class NsppRegisterComponent implements OnInit {

  programData:object = { about: null };
  loading:boolean = false;
  nogginKey = "";
  nogginArr = ["new", "invited", "optin", "requested"];
  nogginStatus = "";
  schoolStatus = "";
  schoolInviteData = null;
  schoolGrades = ["Preschool", "Kindergarten", "Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8"]
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  
  formSchool:FormGroup;

  constructor(    
    public metadataService: MetadataService,
    private sanitizer:DomSanitizer,
    private toastService:AppToastService,
    private fb:FormBuilder,
    public authService: AuthService,
    private cd: ChangeDetectorRef) { 
      this.formSchool = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        email: ['',this.noWhitespaceValidator],
        phone: [''],
        role: [''],
        grades: this.fb.array(this.schoolGrades.map(s => this.fb.control(false)), [ Validators.required ]),
        subject_matter: [''],
        school_name: ['',this.noWhitespaceValidator],
        school_district: ['',this.noWhitespaceValidator],
        school_state: ['',this.noWhitespaceValidator],
        school_website: [''],
        learning_format: ['',Validators.required],
        requires_approval: ['',Validators.required],
        interest: [''],
        school_events: new FormArray([]),
        event_name: [''],
        event_month: [''],
        event_year: [''],
        requires_approval_full_name: [''],
        requires_approval_title: [''],
        requires_approval_email: [''],
        requires_approval_phone: ['']
      });
    }

  ngOnInit() {
    this.metadataService.updateParselyTitle("NSPP Registration - Nickelodeon Community Partner Resources");
    this.metadataService.updateParselyLink(window.location.href);
    this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/Nickelodeon_CPR_logo_white.svg");

    var today = new Date();
    this.formSchool.get('event_year').setValue(today.getFullYear());
    this.formSchool.get('event_month').setValue(this.months[today.getMonth()]);
    this.getSchoolStatus();  
  }

  getSchoolStatus() {
    if(this.authService.isLoggedIn()) {
      //this.schoolStatus = "loading"
      this.loading = true;

      this.authService.getSchoolStatus().subscribe(
        (data) => {
          this.loading = false;
          this.schoolStatus = data['status'];
          localStorage.setItem("NickCommunitySchool", data['status']);

          this.formSchool.get('email').setValue(data['email'])
          this.formSchool.get('name_first').setValue(data['name_first'])
          this.formSchool.get('name_last').setValue(data['name_last'])
          this.formSchool.get('school_name').setValue(data['school_name']);

          if(this.schoolStatus == "new") {
            this.getSchoolInvitation();
          }

        },
        () => {
          this.loading = false;
          this.schoolStatus = "new"
          this.getSchoolInvitation();
        }
      );
    } else {
      if(localStorage.getItem("NickCommunitySchool")) {
        this.schoolStatus = localStorage.getItem("NickCommunitySchool");
        if(this.schoolStatus == "new") {
          this.getSchoolInvitation();
        }
      } else {
        this.schoolStatus = "new"
        this.getSchoolInvitation();
      }
    }
  }

  restartRequest() {
    this.schoolStatus = 'new';
    this.formSchool.reset();
  }

  getSchoolInvitation() {
    if(this.nogginKey) {
      this.loading = true;
      this.authService.getSchoolInvite(this.nogginKey).subscribe(
        (data) => {
          this.schoolStatus = data['status'];
          this.schoolInviteData = data['data'];
          this.formSchool.get('name_first').setValue(data['data']['name_first']);
          this.formSchool.get('name_last').setValue(data['data']['name_last']);
          this.formSchool.get('email').setValue(data['data']['email']);
          this.formSchool.get('role').setValue(data['data']['role'].toLowerCase());
          this.formSchool.get('school_name').setValue(data['data']['school_name']);
          this.formSchool.get('school_district').setValue(data['data']['school_district']);
          this.formSchool.get('school_state').setValue(data['data']['school_state']);
          this.formSchool.get('school_name').disable();
          this.formSchool.get('requires_approval').setValue("0");
          this.loading = false;
        });
    }
  }

  changeSchool() {
    this.formSchool.get('school_name').setValue("")
    this.formSchool.get('school_name').enable();
    this.schoolInviteData = null;
  }

  submitSchoolRequest() {
    //this.schoolStatus = "loading";
    this.loading = true;
    //const val = this.formSchool.value;
    const val = this.formSchool.getRawValue();
    this.authService.postSchoolRequest(val).subscribe(
    (data) => {
      this.loading = false;
      this.schoolStatus = data['status'];
      localStorage.setItem("NickCommunitySchool", data['status']);
    },
    () => {
      this.loading = false;
      this.schoolStatus = "new"
    });
  }

  appendSchoolEvent() {
    var entry = this.fb.group({
      event_name: this.formSchool.get('event_name').value,
      event_month: this.formSchool.get('event_month').value,
      event_year: this.formSchool.get('event_year').value
    });
    var events = this.formSchool.get('school_events') as FormArray;
    events.push(entry); 
    console.log(events)
    this.formSchool.setControl('school_events', events);

    this.formSchool.get('event_name').setValue("");
    //this.formSchool.patchValue('school_events', events);
  }

  removeSchoolEvent(i) {
    var events = this.formSchool.get('school_events') as FormArray;
    events.removeAt(i);
    this.formSchool.setControl('school_events', events);

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

}
