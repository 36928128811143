import { Component, OnInit, OnDestroy, Renderer, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap  } from "@angular/router";
import { AppToastService } from '../_helpers/toast.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  user_hash:string = null;
  id_hash:string = null;
  loading:boolean = false;

  constructor(
    private renderer: Renderer,
    private toastService:AppToastService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.user_hash = params.get('user_hash');
      this.id_hash = params.get('id_hash');
      this.getProgram();
    });
  }

  getProgram() {
    this.loading = true;
    
    //this.slug = name;
    this.authService.postAccessVerify(this.user_hash, this.id_hash).subscribe(
      (data) => {
        this.loading = false;
        let program = localStorage.getItem("NickCommunityProgram");
        if(program) {
          this.router.navigateByUrl('/program/'+program);
        } else {
          this.router.navigateByUrl('/');
        }
        //
        
        
        //this.getFile(this.programData["resource_playbook"]);
        //this.getFile(this.programData["resource_logo"]);

      },
      (err) => {
        this.toastService.show("Error",err);
        this.loading = false;
        this.router.navigateByUrl('/access');
        //this.accountLoaded = false;
      }
    );
  }

}
