import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { routerTransition } from './animations';
import { RouterOutlet, Router, NavigationStart } from '@angular/router';
import { trigger, state,  style, animate, transition } from '@angular/animations';
import { AppToastService } from './_helpers/toast.service';
import { MetadataService } from "./_helpers/metadata.service";

declare global {
  interface Window {
      PARSELY:any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  providers: [AppToastService]
})
export class AppComponent implements OnInit {
  title = 'nickcommunity';
  public isCollapsed:boolean;
  pageData: any[];

  hasLoaded = false;
  canShow = false;
  unloadedMsg = "";

  constructor(
    public metadataService: MetadataService,
    public toastService: AppToastService,
    public authService: AuthService,
    private router:Router) {
    this.isCollapsed = true;
    router.events.subscribe((val) => {
      if(val instanceof NavigationStart) {
        this.isCollapsed = true;
        // change metadata
        // this.authService.getMetadata({slug: val.url}).subscribe(
        //   (data) => {
        //     console.log("metadata")
        //     console.log(val);
        //     let title = "Nickelodeon Community Partner Resources";
        //     this.metadataService.updateTitle(title);
        //   }, () => { }
        // );

      }


    });
  }

  ngOnInit() {
    
    if(window.location.host.indexOf("nickcommunity.com") > -1) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    this.checkGeo();
    //this.getMenu();
  }

  loadParsely() {
    if(window.location.host.indexOf("nickcommunity.com") > -1) {
      // Prod Script
      this.loadSnippet("nickcommunity.com")
    } else {
      // Dev Script
      this.loadSnippet("sandbox.nickcommunity.com")
    }
  }

  checkGeo() {
    this.authService.getGeo().subscribe(
      (data) => {
        this.hasLoaded = true;
        if(data['country']) {
          if(data['country'] == "US") {
            this.canShow = true;
          }
        } else {
          this.canShow = true;
        }
        
        if(this.canShow) {
          this.loadParsely();
        } else {
          this.unloadedMsg = "This page cannot be loaded";
          this.router.navigate(['/']);
        }
      },
      () => {
        this.hasLoaded = true;
        this.canShow = true;
        this.loadParsely();

      }
    );
  }

  loadSnippet(api_key) {
    var parsely=window.PARSELY=window.PARSELY||{};if(parsely._snippetVersion="1.0.0",!parsely.loaded)if(parsely._snippetInvoked)try{window.console&&console.error&&console.error("Parsely snippet included twice")}catch(e){}else{parsely._snippetInvoked=!0,parsely._stubs={onStart:[]},parsely._buildStub=function(e){return function(){parsely._stubs[e].push(arguments)}};for(var curStub in parsely._stubs)parsely._stubs.hasOwnProperty(curStub)&&(parsely[curStub]=parsely._buildStub(curStub))}parsely._load=function(e,s){s=void 0===s?"cdn.parsely.com":s;var r=document.createElement("script");r.id="parsely-cfg",r.type="text/javascript",r.async=!0,r.setAttribute("data-parsely-site",e),r.src="//"+s+"/keys/"+e+"/p.js",document.body.appendChild(r)};

   parsely._load(api_key);

   var PARSELY = window.PARSELY || {};
   PARSELY.spa = PARSELY.spa || {};
   PARSELY.spa.autotrack = false;
  }


  getMenu() {
    // this.authService.getAllPrograms().subscribe(
    //   (data) => {
    //     this.pageData = data;
    //   },
    //   () => {

    //   }
    // );
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  logout = function() {
    this.authService.logout()
    this.router.navigateByUrl('/access');
  }

}
