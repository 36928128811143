import { Component, OnInit, OnDestroy, Renderer, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap  } from "@angular/router";
import { MetadataService } from 'src/app/_helpers/metadata.service';

@Component({
  selector: 'app-blue-psa',
  templateUrl: './blue-psa.component.html',
  styleUrls: ['./blue-psa.component.scss']
})
export class BluePsaComponent implements OnInit, OnDestroy {

  slug = "General";
  video = "";
  cities = ['Atlanta', 'Baltimore', 'Boston', 'Burbank', 'Chicago', 'Cincinnati', 'DC', 'Detroit', 'Florence', 'Houston', 'Inglewood', 'LA', 'Laurel', 'MiamiDade', 'Minneapolis', 'Nashville', 'NewYork', 'Nogales', 'Paducah', 'Philadelphia', 'Portland', 'Richmond', 'Selma', 'StLouis', 'Tuscon',
'Cincinnati_custom', 'Inglewood_custom', 'NY_custom', 'LA_custom', 'MiamiDade_custom', 'Portland_custom']

  constructor(
    public metadataService: MetadataService,
    private renderer: Renderer,
    private router: Router,
    private route: ActivatedRoute,) {

   }

   ngOnInit() {
    this.metadataService.updateParselyTitle("Blue PSA - Nickelodeon Community Partner Resources");
    this.metadataService.updateParselyLink(window.location.href);
    this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/Nickelodeon_CPR_logo_white.svg");

    this.route.paramMap.subscribe((params: ParamMap) => {
      if(params.get('page')) {
        this.slug = params.get('page');

        if(this.cities.includes(this.slug)) {
        } else {
          this.router.navigate(['/blue_psa']);
          this.slug = "General";
        }

      }
      
      this.video = "/api/downloads/BluesClues_ParentedDirected_1_PSA_v6_"+this.slug+".mp4";
    });

    
    this.renderer.setElementClass(document.body, 'theme-bluepsa', true);
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-bluepsa', false);
  }

}
