import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppToastService {
  toasts: any[] = [];

  show(header: string, body: string, delay?: number) {
    this.toasts.push({ header, body, delay });
  }

  remove() {
      this.toasts.pop();
  }
}