import { Component, OnInit, OnDestroy, AfterContentInit, Renderer, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
//import {} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy, AfterContentInit {

  profileForm:FormGroup;
  eventForm:FormGroup;
  inviteForm:FormGroup;
  accountLoaded:boolean;
  defaultTab:string;
  activeIdString:string;
  accountData:object;
  loading:boolean = false;

  @ViewChild('tabs', {static: true}) public tabs:NgbTabset;

  constructor(private fb:FormBuilder, 
    public authService: AuthService, 
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer) {
      this.renderer.setElementClass(document.body, 'theme-evergreen', true);
      this.accountLoaded = null;
      this.profileForm = this.fb.group({
        name_first: ['',Validators.required],
        name_last: ['',Validators.required],
        name_org: [''],
        title: [''],
        email: ['',Validators.required],
        address_line1: [''],
        address_line2: [''],
        address_city: [''],
        address_state: [''],
        address_zip: [''],
        phone: [''],
      });
      this.eventForm = this.fb.group({
        organization_name: ['',Validators.required],
        contact_name: ['',Validators.required],
        date_event: ['',Validators.required],
        address_city: ['',Validators.required],
        address_state: ['',Validators.required],
        attendees: ['',Validators.required],
        about: ['',Validators.required]
      });
      this.inviteForm = this.fb.group({
        name_first: ['',Validators.required],
        name_last: ['',Validators.required],
        title: ['',Validators.required],
        email: ['',Validators.required]
      });

  }

  ngAfterContentInit() {
    console.log(this.defaultTab);
    if(this.tabs && this.defaultTab) {
      this.tabs.select(this.defaultTab);
    }
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }

  ngOnInit() {
    this.defaultTab = this.route.snapshot.paramMap.get("tab")
    this.activeIdString = this.defaultTab;
    
    this.getAccount();
  }


  getAccount() {
    this.accountLoaded = null;
    this.authService.getAccount().subscribe(
      (data) => {
        this.accountData = data;
        this.accountLoaded = true;
        this.profileForm.setValue(data["profile"])
        console.log(this.tabs);
        if(this.tabs && this.defaultTab) {
          this.tabs.select(this.defaultTab);
        }
      },
      () => {
        this.accountLoaded = false;
      }
    );
  }

  saveProfile() {
    const val = this.profileForm.value;
      if (val.email && val.name_first) { 
          this.authService.saveProfile(val)
              .subscribe(
                  () => {
                      console.log("User is logged in");
                      //this.router.navigateByUrl('/program/dayofplay');
                  }
              );
      }
  }
  
  saveEvent() {
    const val = this.profileForm.value;
      if (val.email && val.name_first) { 
          this.authService.saveProfile(val)
              .subscribe(
                  () => {
                      console.log("User is logged in");
                      //this.router.navigateByUrl('/program/dayofplay');
                  }
              );
      }
  }

  sendInvite() {
    const val = this.inviteForm.value;
      if (val.email && val.name_first) { 
          this.authService.saveInvite(val)
              .subscribe(
                  () => {
                    this.profileForm.reset();
                    this.getAccount();
                      //this.router.navigateByUrl('/program/dayofplay');
                  },
                  (err) => {
                    alert(err);
                  }
              );
      }
  }

  logout = function() {
    alert('logging out');
    this.authService.logout()
    
    this.router.navigateByUrl('/');
  }

}
