import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RoutesRecognized   } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit, OnDestroy {
  form: FormGroup;
  type: String = "login";
  error: String = null;
  loading: Boolean = false;
  isSent: Boolean = false;

  constructor(private fb: FormBuilder,
    public authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer) {
      this.renderer.setElementClass(document.body, 'theme-evergreen', true);

      this.form = this.fb.group({
        email: new FormControl('',[
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])
      });
  }

  ngOnInit() {
    if(this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/account');
    }
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }



  login() {
    const val = this.form.value;
    if (val.email) {
      this.loading = true;
      this.error = null;
      this.authService.postAccess(val.email)
        .subscribe(
          () => {
            this.loading = false;
            this.isSent = true;
            //this.router.navigateByUrl('/program/dayofplay');
          },
          (msg) => {
            this.loading = false;
            if (msg) {
              this.error = msg;
            }
          }
        );
    } else {
      this.error = "Email is required."
    }
  }

}
