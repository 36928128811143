import { Component, OnDestroy, OnInit, Renderer } from '@angular/core';
import { ActivatedRoute, Router, ParamMap  } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, OnDestroy {
  loading:boolean = false;
  loaded:boolean = false;
  error_title:string = null;
  error_body:string = null;

  survey_id:string = null;
  survey_code:string = null;
  survey_title:string = null;
  survey_slug:string = null;

  complete_message:string = null;
  
  forms:Array<FormGroup> = [];
  // formKOTYAMB2021:FormGroup;
  // formKOTY2021:FormGroup;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer,
    public authService: AuthService,
    private fb:FormBuilder,) {

      this.forms['Nick-tastic_Teacher'] = this.fb.group({
        NomineesFirstName: ['',this.noWhitespaceValidator],
        NomineesLastName: ['',this.noWhitespaceValidator],
        NomineesEmailAddress: ['',this.noWhitespaceValidator],
        NomineesSchool: ['',this.noWhitespaceValidator],
        City: [''],
        State: [''],
        JobFunction: ['',this.noWhitespaceValidator],
        GradeLevels: [''],
        SubjectTaught: [''],
        Comments: ['',this.noWhitespaceValidator],
        FirstName: ['',this.noWhitespaceValidator],
        LastName: ['',this.noWhitespaceValidator],
        School: ['',this.noWhitespaceValidator],
        EmailAddress: ['',this.noWhitespaceValidator],
      });

      this.forms['Playworks2022'] = this.fb.group({
        FirstName: ['',this.noWhitespaceValidator],
        School: ['',this.noWhitespaceValidator],
        EmailAddress: ['',this.noWhitespaceValidator],
        Timing_Answered: ['',Validators.requiredTrue],
        Timing: new FormArray([]),
        Timing_LateJanuary: [false],
        Timing_EarlyFebruary: [false],
        Timing_LateFebruary: [false],
        Timing_EarlyMarch: [false],
        Timing_LateMarch: [false],
        Timing_EarlyApril: [false],
        Timing_LateApril: [false],
        TimeOfDay: ['',this.noWhitespaceValidator],
        Timezone: ['',this.noWhitespaceValidator],
        Frequency: ['',this.noWhitespaceValidator],
        FrequencyOther: [''],
        Choice_Answered: ['',Validators.requiredTrue],
        Choice: new FormArray([]),
        C_PowerOfPlay: [false],
        C_GroupManagement: [false],
        C_ClassroomGameTime: [false],
        C_IndoorRecessDesign: [false],
        C_YouthLeadershipDevelopment: [false],
        C_StaffWellness: [false],
        C_EffectiveGameFacilitation: [false],
        C_VirtualFacilitation: [false],
      });

      this.forms['KOTY_PostPresentation_Survey'] = this.fb.group({
        Valueable: ['',this.noWhitespaceValidator],
        ValueableComment: [''],
        Impactful: ['',this.noWhitespaceValidator],
        ImpactfulComment: [''],
        Educational: ['',this.noWhitespaceValidator],
        EducationalComment: [''],
        WhatCanBeImproved: ['',this.noWhitespaceValidator],
        LikedMost: ['',this.noWhitespaceValidator],
        LikeToBeConsidered: ['',this.noWhitespaceValidator],
        FirstName: ['',this.noWhitespaceValidator],
        LastName: ['',this.noWhitespaceValidator],
        Title: [''],
        School: ['',this.noWhitespaceValidator],
        EmailAddress: ['',this.noWhitespaceValidator],
      });

      this.forms['SchoolProgramSurvey2021'] = this.fb.group({
        FirstName: ['',this.noWhitespaceValidator],
        LastName: ['',this.noWhitespaceValidator],
        School_SchoolDistrict: ['',this.noWhitespaceValidator],
        EmailAddress: ['',this.noWhitespaceValidator],
        MostNeeded_1: ['',this.noWhitespaceValidator],
        MostNeeded_2: [''],
        MostNeeded_3: [''],
        MostNeeded_4: [''],
        MostNeeded_5: [''],
        HelpfulResourceType: ['',Validators.required],
        PhysicalAssets: ['',Validators.required],
        TopSubjects_Answered: ['',Validators.requiredTrue],
        TopSubjects: new FormArray([]),
        Support_Answered: ['',Validators.requiredTrue],
        Support: new FormArray([]),
        Support_Other: [''],
        Support_Explanation: [''],
        VirtualOfferings: ['',Validators.required],
        InSchoolActivations: ['',Validators.required],
        BestCommunicationStyle: ['',Validators.required],
        BestCommunication_Other: [''],
        CommunicationBarriers: ['',Validators.required],
        CommunicationBarriers_Other: [''],
        GeneralFeedback: [''],
        GiftFirstName: [''],
        GiftLastName: [''],
        GiftEmail: [''],
        GiftEmail_Confirm: ['']
      });

//KOTY2021MasterClass
      this.forms['KOTY2021MasterClass'] = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        role: ['',this.noWhitespaceValidator],
        school: ['',this.noWhitespaceValidator],
        phone: ['',this.noWhitespaceValidator],
        email: ['',this.noWhitespaceValidator]
      });

      this.forms['KOTY2021'] = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        role: ['',this.noWhitespaceValidator],
        role_other: [''],
        phone: ['',this.noWhitespaceValidator],
        email: ['',this.noWhitespaceValidator],

        interested: [false,Validators.requiredTrue],
        access: [false],
        access_reason: ['', this.noWhitespaceValidator],
        guarantee: [false,Validators.requiredTrue],
        not_interested: [false],

        request_for: ['',Validators.required],
        classroom_subject: [''],
        classroom_quantity: [''],
        classroom_teacher: [''],
        classroom_phone: [''],
        classroom_email: [''],
        

        grade_level: [''],
        grade_quantity: [''],
        grade_teacher: [''],
        grade_phone: [''],
        grade_email: [''],


        topic_social: [false],
        topic_education: [false],
        topic_health: [false],
        topic_giving: [false],
        topic_arts: [false],
        topic_none: [false],

        available_1: [false,Validators.requiredTrue],
        available_8: [false,Validators.requiredTrue],
        available_15: [false,Validators.requiredTrue],
        available_22: [false,Validators.requiredTrue],
        available_open: [false,Validators.requiredTrue],
        available_none: [false,Validators.requiredTrue],
        available_morning: [false,Validators.requiredTrue],
        available_afternoon: [false,Validators.requiredTrue],
        available_either: [false,Validators.requiredTrue],
        school_hours: ['', this.noWhitespaceValidator],
        notes: [''],
      })

      this.forms['KOTYAMB2021'] = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        main_name_first: ['',this.noWhitespaceValidator],
        main_name_last: ['',this.noWhitespaceValidator],
        phone: ['',this.noWhitespaceValidator],
        email: ['',this.noWhitespaceValidator],
        interested: [false,Validators.requiredTrue],
        participate: [false,Validators.requiredTrue],
        access: [false],
        access_reason: ['', this.noWhitespaceValidator],
        guarantee: [false,Validators.requiredTrue],
        available_1: [false,Validators.requiredTrue],
        available_8: [false,Validators.requiredTrue],
        available_15: [false,Validators.requiredTrue],
        available_22: [false,Validators.requiredTrue],
        available_open: [false,Validators.requiredTrue],
        available_none: [false,Validators.requiredTrue],
        available_morning: [false,Validators.requiredTrue],
        available_afternoon: [false,Validators.requiredTrue],
        available_either: [false,Validators.requiredTrue],
        notes: [''],
      })

      this.renderer.setElementClass(document.body, 'theme-evergreen', true);

     }

     ngOnDestroy() {
      this.renderer.setElementClass(document.body, 'theme-evergreen', false);
    }

    onCheckChange(event, arr_name = "myChoices", maximum = 0) {
      const formArray: FormArray = this.forms[this.survey_slug].get(arr_name) as FormArray;
    
      
      /* Selected */
      if(event.target.checked){
        // Add a new control in the arrayForm
        if(maximum == 0 || formArray.length < maximum) {
          formArray.push(new FormControl(event.target.value));
        } else {
          event.target.checked = false;
        }
      }
      /* unselected */
      else{
        // find the unselected element
        let i: number = 0;
    
        formArray.controls.forEach((ctrl: FormControl) => {
          if(ctrl.value == event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }
    
          i++;
        });
      }

      if(formArray.length > 0) {
        this.forms[this.survey_slug].get(arr_name+"_Answered").clearValidators();
      } else {
        this.forms[this.survey_slug].get(arr_name+"_Answered").setValidators(Validators.requiredTrue);
      }
      this.forms[this.survey_slug].get(arr_name+"_Answered").updateValueAndValidity()
      console.log(formArray.value)
    }

  toggleAccess(evt) {
    if(evt.target.checked) {
      this.forms[this.survey_slug].get("access_reason").clearValidators();
    } else {
      this.forms[this.survey_slug].get("access_reason").setValidators([this.noWhitespaceValidator]);
    }
    this.forms[this.survey_slug].get("access_reason").updateValueAndValidity()
  }

  toggleAvailable(evt) {
    var arr = ["available_1", "available_8", "available_15", "available_22", "available_open", "available_none"]
    this.toggleGeneric(arr, evt.target.checked);
  }

  toggleTime(evt) {
    var arr = ["available_morning", "available_afternoon", "available_either"]
    this.toggleGeneric(arr, evt.target.checked);
  }

  toggleGeneric(arr, is_checked = false) {
    if(is_checked == false) {
      for (let i = 0; i < arr.length; i++) {
        console.log(i + " - " + this.forms[this.survey_slug].get(arr[i]).value)
        if (this.forms[this.survey_slug].get(arr[i]).value == true) {
          is_checked = true;
        }
      }
    }
    console.log(is_checked);
    for (let i = 0; i < arr.length; i++) {
      if(is_checked) {
        this.forms[this.survey_slug].get(arr[i]).clearValidators();
      } else {
        this.forms[this.survey_slug].get(arr[i]).setValidators(Validators.requiredTrue);
      }
      this.forms[this.survey_slug].get(arr[i]).updateValueAndValidity()
    }
  }

  ngOnInit() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', true);
    
    this.loading = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      //if(params.get('survey_id')) {
        this.survey_id = params.get('survey_id');
        // if(params.get('survey_code')) {
          this.survey_code = params.get('survey_code');
          this.verifyCode();
        // } else {
        //   this.loading = false;
        //   this.error_title = "Sorry, this link is invalid.";
        // }
      // } else {
      //   this.loading = false;
      //   this.error_title = "Sorry, this link is invalid.";
      // }
    });
  }

  verifyCode() {
    //this.msg_error = "Sorry, this link is invalid.";
    var args = {
      survey_id: this.survey_id,
      survey_code: this.survey_code
    }
    this.loading = true;
    this.authService.postSurveyVerifyStatus(args).subscribe(
      (data) => {
        this.loading = false;
        console.log(data)
        this.survey_title = data['title'];
        this.survey_slug = data['survey_slug'];

        this.prefillForm(data);
        //this.nogginStatus = data['status'];
        //localStorage.setItem("NickCommunityNoggin", data['status']);
      },
      (err) => {
        this.loading = false;
        console.log("oops")
        console.log(err)
        this.error_title = (err.title) ? err.title : "Oops, something didn't work";
        this.error_body = (err.message) ? err.message : err;
        //this.nogginStatus = "new"
      }
    );
  }

  prefillForm(data) {
    if(this.survey_slug == "SchoolProgramSurvey2021") {
      this.forms['SchoolProgramSurvey2021'].patchValue({
        FirstName: data['name_first'], 
        LastName: data['name_last'], 
        //school: data['school'], 
        EmailAddress: data['email'], 
      });
    } else if(this.survey_slug == "Playworks2022") {
      this.forms['Playworks2022'].patchValue({
        FirstName: data['name_first'], 
        // LastName: data['name_last'], 
        school: data['school'], 
        EmailAddress: data['email'], 
      });
    }
  }

  submitSurvey() {

    if(this.forms[this.survey_slug].valid) {
    const val = this.forms[this.survey_slug].value;
    const data = {
      survey_id: this.survey_id,
      survey_code: this.survey_code,
      form_data: val
    }
    this.loading = true;
    this.authService.postSurveySubmit(data).subscribe(
      (data) => {
        this.loading = false;
        this.complete_message = data['message'];
        //this.showTalentSuccess = true;
        this.forms[this.survey_slug].reset();
      },
      () => {
        this.loading = false;
      });
    } else {
      console.log("invalid")
    }
  }

  same_contact() {
    this.forms[this.survey_slug].patchValue({
      main_name_first: this.forms[this.survey_slug].get("name_first").value,
      main_name_last: this.forms[this.survey_slug].get("name_last").value
      // formControlName2: myValue2 (can be omitted)
    });
    return false;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public availabilityValidator(formControl: AbstractControl) {
    var _this = this;
    if (!formControl.parent) {
      return null;
    }

    var arr = ["available_1", "available_8", "available_15", "available_22", "available_open", "available_none"]
    var is_checked = false;

    for (let i = 0; i < arr.length; i++) {
      console.log(formControl.parent.get(arr[i]).value)
      if (formControl.parent.get(arr[i]).value == true) {
        is_checked = true;
      }
    }

    
    for (let i = 0; i < arr.length; i++) {
      if(is_checked) {
        return Validators.requiredTrue(formControl); ;
      } else {
        return null
      }
    }

    return null;
  }

  public AccessReasonValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    
    if (formControl.parent.get('access').value == false) {
      return Validators.required(formControl); 
    }
    return null;
  }

  public AccessValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('access_reason').value != "") {
      return Validators.requiredTrue(formControl); 
    }
    return null;
  }

  UsStates = ['ALABAMA','ALASKA','AMERICAN SAMOA','ARIZONA','ARKANSAS','CALIFORNIA','COLORADO','CONNECTICUT','DELAWARE','DISTRICT OF COLUMBIA','FEDERATED STATES OF MICRONESIA','FLORIDA','GEORGIA','GUAM','HAWAII','IDAHO','ILLINOIS','INDIANA','IOWA','KANSAS','KENTUCKY','LOUISIANA','MAINE','MARSHALL ISLANDS','MARYLAND','MASSACHUSETTS','MICHIGAN','MINNESOTA','MISSISSIPPI','MISSOURI','MONTANA','NEBRASKA','NEVADA','NEW HAMPSHIRE','NEW JERSEY','NEW MEXICO','NEW YORK','NORTH CAROLINA','NORTH DAKOTA','NORTHERN MARIANA ISLANDS','OHIO','OKLAHOMA','OREGON','PALAU','PENNSYLVANIA','PUERTO RICO','RHODE ISLAND','SOUTH CAROLINA','SOUTH DAKOTA','TENNESSEE','TEXAS','UTAH','VERMONT','VIRGIN ISLAND','VIRGINIA','WASHINGTON','WEST VIRGINIA','WISCONSIN','WYOMING']


}
