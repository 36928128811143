import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppToastService } from '../_helpers/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  form:FormGroup;
  id:string;
  loaded:boolean = null;
  error:string = null;
  is_invite:boolean = null;

  constructor(
    private toastService:AppToastService,
    private fb:FormBuilder, 
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private renderer: Renderer) {
      this.renderer.setElementClass(document.body, 'theme-evergreen', true);

      this.form = this.fb.group({
        password: ['',Validators.required],
        password_verify: ['']
      }, { validator: this.checkPasswords });
    }

    ngOnDestroy() {
      this.renderer.setElementClass(document.body, 'theme-evergreen', false);
    }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id")
    if(!this.id) {
      this.router.navigateByUrl('/');
    }

    this.is_invite = this.route.snapshot.data['is_invite'];

    this.authService.verifyResetCode(this.id)
    .subscribe(
        (data) => {
          this.loaded = true;
          this.is_invite = data['is_invite'];
            //console.log("Request sent");
            //this.router.navigateByUrl('/');
        },
        () => {
          // error
          //this.toastService.show("Error","Reset link is either too old or has already been used. Please try again.", 10000);
          //this.router.navigateByUrl('/');
          this.loaded = false;
        }
    );
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let password = group.controls.password.value;
    let password_verify = group.controls.password_verify.value;
    return password === password_verify ? null : { notSame: true }     
  }

  submitForm = function() {
    //resetPassword
    const val = this.form.value;
    if (this.form.valid) {
      this.error = null;
      this.authService.resetPassword(val.password, this.id)
        .subscribe(
          () => {
            
            this.toastService.show("Success!","Password was set successfully");
            this.router.navigateByUrl('/login');
          },
          (msg) => {
            if (msg) {
              this.error = msg;
            }
          }
        );
    } else {
      this.error = "Email and password required."
    }
  }

}
