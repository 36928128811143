import { Component, OnInit, OnDestroy, Renderer, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { NgbdModalContent } from '../_helpers/modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {

  form:FormGroup;
  loading:boolean = false;
  successMessage = null;

  constructor(private fb:FormBuilder, 
    public authService: AuthService, 
    private router: Router,
    private renderer: Renderer,
    private modalService: NgbModal) {
      this.renderer.setElementClass(document.body, 'theme-evergreen', true);

      this.form = this.fb.group({
        name_first: ['',Validators.required],
        name_last: ['',Validators.required],
        email: ['',Validators.required],
        organization: ['',Validators.required],
        city: ['',Validators.required],
        state: ['',Validators.required],
        is_nspp: [false],
        not_sure: [false]
      });
}

ngOnInit() {
  if(this.authService.isLoggedIn()) {
    this.router.navigateByUrl('/account');
  }
}

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }

  signup() {
    const val = this.form.value;
      if (val.email && val.organization) {
          this.loading = true;
          this.authService.signup(val.name_first, val.name_last, val.email, val.organization, val.city, val.state, val.is_nspp, val.not_sure)
              .subscribe(
                  (result) => {
                      this.loading = false;
                      this.successMessage = result["message"];
                      //const modalRef = this.modalService.open(NgbdModalContent, { centered: true });
                      //modalRef.componentInstance.title = result["title"];
                      //modalRef.componentInstance.message = result["message"];
                      //this.router.navigateByUrl('/program/dayofplay');
                  }, 
                  (error) => {
                    this.loading = false;
                    console.log(error)

                  }
              );
      }
  }

}

// @Component({
//   selector: 'ngbd-modal-content',
//   template: `
//     <div class="modal-header">
//       <h4 class="modal-title">Hi there!</h4>
//       <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
//         <span aria-hidden="true">&times;</span>
//       </button>
//     </div>
//     <div class="modal-body">
//       <p>Hello, {{name}}!</p>
//     </div>
//     <div class="modal-footer">
//       <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
//     </div>
//   `
// })
// export class NgbdModalContent {
//   @Input() name;

//   constructor(public activeModal: NgbActiveModal) {}
// }