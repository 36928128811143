import { Component, OnInit, Renderer } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { MetadataService } from 'src/app/_helpers/metadata.service';
import { AppToastService } from '../../_helpers/toast.service';

@Component({
  selector: 'app-school-invite',
  templateUrl: './school-invite.component.html',
  styleUrls: ['./school-invite.component.scss']
})
export class SchoolInviteComponent implements OnInit {
  slug = "schoolpartnership";
  key = "";
  schoolStatus = "";
  loading = false;
  formSchool:FormGroup;
  schoolInviteData = null;
  schoolGrades = ["Preschool", "Kindergarten", "Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8"]
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(
    public metadataService: MetadataService,
    private toastService:AppToastService,
    private renderer: Renderer,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private fb:FormBuilder,) {
      this.formSchool = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        email: ['',this.noWhitespaceValidator],
        phone: [''],
        role: [''],
        grades: this.fb.array(this.schoolGrades.map(s => this.fb.control(false)), [ Validators.required ]),
        subject_matter: [''],
        school_name: ['',this.noWhitespaceValidator],
        school_district: ['',this.noWhitespaceValidator],
        school_state: ['',this.noWhitespaceValidator],
        // school_website: [''],
        // learning_format: ['',Validators.required],
        // requires_approval: ['',Validators.required],
        // interest: [''],
        // school_events: new FormArray([]),
        // event_name: [''],
        // event_month: [''],
        // event_year: [''],
        // requires_approval_full_name: [''],
        // requires_approval_title: [''],
        // requires_approval_email: [''],
        // requires_approval_phone: ['']
      });
     }

    getSchoolStatus() {
      if(this.authService.isLoggedIn()) {
        //this.schoolStatus = "loading"
        this.loading = true;
        this.authService.getSchoolStatus().subscribe(
          (data) => {
            this.loading = false;
            this.schoolStatus = data['status'];
            localStorage.setItem("NickCommunitySchool", data['status']);
  
            this.formSchool.get('email').setValue(data['email'])
            this.formSchool.get('name_first').setValue(data['name_first'])
            this.formSchool.get('name_last').setValue(data['name_last'])
            this.formSchool.get('school_name').setValue(data['school_name']);
  
            if(this.schoolStatus == "new") {
              this.getSchoolInvitation();
            } else {
              this.goToNSPP();
            }
  
          },
          () => {
            this.loading = false;
            this.schoolStatus = "new"
            this.getSchoolInvitation();
          }
        );
      } else {
        this.schoolStatus = "new"
          this.getSchoolInvitation();
      }
    }

    goToNSPP() {
      this.router.navigate(['/schoolpartnership']);
    }

    getSchoolInvitation() {
      console.log("get invite " + this.key)
      if(this.key) {
        this.loading = true;
        this.authService.getSchoolPartnerInvitation(this.key).subscribe(
          (data) => {
            this.schoolStatus = data['status'];
            this.schoolInviteData = data['data'];
            // this.formSchool.get('name_first').setValue(data['data']['name_first']);
            // this.formSchool.get('name_last').setValue(data['data']['name_last']);
            // this.formSchool.get('email').setValue(data['data']['email']);
            // this.formSchool.get('role').setValue(data['data']['role'].toLowerCase());
            this.formSchool.get('school_name').setValue(data['data']['school_name']);
            this.formSchool.get('school_district').setValue(data['data']['school_district']);
            this.formSchool.get('school_state').setValue(data['data']['school_state']);
            this.formSchool.get('school_name').disable();
            this.formSchool.get('school_district').disable();
            this.formSchool.get('school_state').disable();
            // this.formSchool.get('requires_approval').setValue("0");
            this.loading = false;
          },
          (data) => {
            console.log(data)
            this.loading = true;
            this.schoolStatus = "new";
            this.goToNSPP();
            
          });
      }
    }

    err = null;
    submitSchoolRequest() {
      this.err = null;
      //this.schoolStatus = "loading";
      this.loading = true;
      //const val = this.formSchool.value;
      let val = this.formSchool.getRawValue();
      val.code = this.key;
      this.authService.postSchoolPartnerRequest(val).subscribe(
      (data) => {
        console.log("success")
        if(data['message']) {
          //this.toastService.show("Success",data['message']);
        }
        this.loading = false;
        this.schoolStatus = data['status'];
        localStorage.setItem("NickCommunitySchool", data['status']);
      },
      (err) => {
        
        this.err = "Sorry, the email address you used may not be eligible. Please try again.";
        //this.toastService.show("Error","Sorry, the email address you used may not be eligible. Please try again.");

        this.loading = false;
        this.schoolStatus = "new"
      })
    }

  ngOnInit() {
    this.metadataService.updateParselyTitle("School Invite - Nickelodeon Community Partner Resources");
    this.metadataService.updateParselyLink(window.location.href);
    this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/Nickelodeon_CPR_logo_white.svg");

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.renderer.setElementClass(document.body, 'theme-'+this.slug, true);

      if(params.get('key')) {
        this.key = params.get('key');
      } else {
        this.goToNSPP()
      }

      this.getSchoolStatus(); 
    });
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-'+this.slug, false);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

}
