import { Component, OnInit, OnDestroy, Renderer, ViewChild, ElementRef} from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import {MatSort} from '@angular/material/sort';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AdminComponent implements OnInit {

  loading:boolean;
  adminData:object;
  nogginAdminData:object;
  schoolAdminData:object;
  feedbackId;
  feedbackData;
  socialData;
  socialId;
  activeIdString;
  inviteForm:FormGroup;
  nogginApproveForm:FormGroup;
  schoolApproveForm:FormGroup;
  eblastRemainingCount = 0;

  displayedColumns = ['id', 'name_first', 'name_last', 'email', 'title', 'name_org', 'date_created'];
  displayedNogginColumns = ['id', 'name', 'email', 'organization', 'is_approved', 'date_created'];
  //displayedSchoolColumns = ['name', 'email', 'school_name', 'is_approved', 'date_created'];
  displayedSchoolColumns = ['name', 'email', 'school_name', 'is_approved', 'date_created'];
  expandedSchoolColumns = {
    name: "Name",
    email: "Email",
    phone: "Phone",
    role: "Role",
    grades: "Grades",
    subject_matter: "Subject Matter Taught",
    school_name: "School Name",
    school_district: "School District",
    school_state: "State",
    school_website: "Website",
    learning_format: "Learning Format",
    requires_approval: "Requires Approval",
    interest: "Interest",
    date_created: "Date Submitted",
    date_modified: "Date Modified"
  }
  expandedElement: object | null;
  dataSource = new TableVirtualScrollDataSource();
  nogginDataSource = new TableVirtualScrollDataSource();
  schoolDataSource = new TableVirtualScrollDataSource();
  nogginSource = "users";
  schoolSource = "registration";
  nogginApprovedFilter = "null";
  schoolApprovedFilter = "null";
  filterSelectObj = [];
  filterValues = {};

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<object[]>;
  fruits: object[] = [];
  allFruits: object[] = [{id: 1, name: "test"}];

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private fb:FormBuilder,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer) {
      this.renderer.setElementClass(document.body, 'theme-admin', true);

      this.adminData = {
        events: [],
        feedback: [],
        signups: [],
        social: [],
        users: [],
        form: [],
        chat: []
      }
      this.inviteForm = this.fb.group({
        name_first: ['',Validators.required],
        name_last: ['',Validators.required],
        title: ['',Validators.required],
        email: ['',Validators.required]
      });

      this.schoolApproveForm = this.fb.group({
        name_first: [''],
        name_last: [''],
        email: [''],
        phone: [''],
        role: [''],
        grades: [''],
        subject_matter: [''],
        school_name: [''],
        school_district: [''],
        school_state: [''],
        school_website: [''],
        learning_format: [''],
        // requires_approval: [''],
        // requires_approval_full_name: [''],
        // requires_approval_title: [''],
        // requires_approval_email: [''],
        // requires_approval_phone: [''],

        secondary_name_first: [''],
        secondary_name_last: [''],
        secondary_email: [''],
        secondary_phone: [''],
        secondary_role: [''],
        school_address: [''],
        school_grades: [''],
        school_pop: [''],
        school_demo: [''],
        school_type: [''],
        school_title_1: [''],
        us_reps: [''],
        distribution_partners: [''],
        notes: [''],

        email_rep: [''],

        is_approved: [''],
        is_junk: [''],
        is_waitlist: [''],
      });

      this.nogginApproveForm = this.fb.group({
        unique_url_id: ['',emailConditionallyRequiredValidator],
        is_approved: ['',Validators.required]
      });

      // Object to create Filter for
      this.filterSelectObj = [
        {
          name: 'STATUS',
          columnProp: 'is_approved',
          options: []
        }
      ]

      this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
        startWith(null),
        map((fruit: object | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));

  }

  
  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-admin', false);
    this.clearRefreshInterval()
  }

  ngOnInit() {
    if(this.authService.getPermission() == '1') {
      this.getOverview()
      this.setRefreshInterval();
    } else {
      this.router.navigateByUrl('/');
    }

    this.nogginApproveForm.get('is_approved').valueChanges
    .subscribe(value => {
        this.nogginApproveForm.get('unique_url_id').updateValueAndValidity();
    });
  }

  setRefreshInterval() {
    this.getChat();
    this.chatRefreshInterval = setInterval(() => {
      this.getChat();
    }, 6000000)
  }
  clearRefreshInterval() {
    if (this.chatRefreshInterval) {
      clearInterval(this.chatRefreshInterval);
    }
  } 
  resetRefreshInterval() {
    this.clearRefreshInterval();
    this.setRefreshInterval();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyNogginFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.nogginDataSource.filter = filterValue.trim().toLowerCase();
  }

  approveRegistration(id) {
    this.loading = true;
    this.authService.approveRegistration(id).subscribe(
      (data) => {
        this.loading = false;
        this.getOverview();
      },
      (err) => {
        alert(err);
        this.loading = false;
      }
    );
  }

  archiveItem(event:MouseEvent, id, table) {
    event.stopPropagation();
    if(table == "form") {
      this.formData = null;
      this.formId = null;
    }
    this.loading = true;
    this.authService.archiveItem(id, table).subscribe(
      (data) => {
        this.loading = false;
        this.getOverview();
        //this.adminData = data;
      },
      () => {
        this.loading = false;
        //this.accountLoaded = false;
      }
    );
  }

  getOverview() {
    this.loading = true;
    this.authService.getAdminOverview(null).subscribe(
      (data) => {
        this.loading = false;
        var chat = this.adminData["chat"]
        this.adminData = data;
        this.adminData["chat"] = chat;
        
        this.dataSource = new TableVirtualScrollDataSource(this.adminData['users']);
        this.allFruits = this.adminData['users'];
        // this.getNogginOverview();
        this.getSchoolOverview();
      },
      () => {
        this.loading = false;
        //this.accountLoaded = false;
      }
    );
  }

  userGroupFilter = 1;
  getUsers() {
    this.loading = true;
    var args = { userGroup: this.userGroupFilter }
    this.authService.getAdminUsers(args).subscribe(
      (data) => {
        this.loading = false;
        this.adminData['users'] = data['users'];
        this.dataSource = new TableVirtualScrollDataSource(this.adminData['users']);
        this.allFruits = this.adminData['users'];
      },
      () => {
        this.loading = false;
      }
    );
  }

  userGroupDetails = null;
  userGroupId = null;
  viewUserGroup(id) {
    this.loading = true;
    this.userGroupId = id;
    var args = { id: id }
    this.authService.getAdminUserGroup(args).subscribe(
      (data) => {
        this.loading = false;
        this.userGroupDetails = data;
      },
      () => {
        this.loading = false;
      }
    );

  }

  // School
  
  getSchoolOverview() {
    this.loading = true;
    this.authService.getAdminSchoolOverview(null).subscribe(
      (data) => {
        this.loading = false;
        this.schoolAdminData = data;  
        this.setSchoolSource(this.schoolSource);
        //this.nogginDataSource = new TableVirtualScrollDataSource(this.nogginAdminData['requests']);
      },
      () => {
        this.loading = false;
      }
    );
  }
  setSchoolSource(source) {
    this.schoolSource = source;
    this.schoolDataSource = new TableVirtualScrollDataSource(this.schoolAdminData[source]);
    this.schoolDataSource.filterPredicate = this.createFilter();   
    this.setSchoolApprovedFilter(this.schoolApprovedFilter);
  }
  // setSchoolApprovedFilter(filter) {
  //   this.schoolApprovedFilter = filter;
  //   this.filterValues["is_approved"] = filter
  //   this.schoolDataSource.filter = JSON.stringify(this.filterValues)
  // }
  setSchoolApprovedFilter(filter, key = "is_approved") {
    this.schoolApprovedFilter = filter;
    this.filterValues = {};
    this.filterValues[key] = filter
    this.schoolDataSource.filter = JSON.stringify(this.filterValues)
  }

  // Noggin
  getNogginOverview() {
    this.loading = true;
    this.authService.getAdminNogginOverview(null).subscribe(
      (data) => {
        this.loading = false;
        this.nogginAdminData = data;  
        this.setNogginSource(this.nogginSource);
        //this.nogginDataSource = new TableVirtualScrollDataSource(this.nogginAdminData['requests']);
      },
      () => {
        this.loading = false;
      }
    );
  }

  setNogginSource(source) {
    this.nogginSource = source;
    this.nogginDataSource = new TableVirtualScrollDataSource(this.nogginAdminData[source]);
    this.nogginDataSource.filterPredicate = this.createFilter();   
    this.setNogginApprovedFilter(this.nogginApprovedFilter);
  }

  setNogginApprovedFilter(filter) {
    this.nogginApprovedFilter = filter;
    this.filterValues["is_approved"] = filter
    this.nogginDataSource.filter = JSON.stringify(this.filterValues)

  }

  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  selectedProgramId = null;
  programData = null;
  selectProgram(programId) {
    this.loading = true;
    this.authService.getAdminProgramDetails(programId).subscribe(
      (data) => {
        this.loading = false;
        this.programData = data;
      }
    );

  }

  selectedElementID = null;
  extendedSchoolTab = "info";
  selectElement(row) {
    this.extendedSchoolTab = "info";
    this.expandedElement = this.expandedElement === row ? null : row;
    this.selectedElementID = row.id;
    // this.nogginApproveForm.reset();
    this.loading = true;
    this.authService.getAdminSchoolExtended(row.id).subscribe(
      (data) => {
        this.loading = false;
        console.log(data);

        if(data['info']) {
          var fields = ['name_first', 'name_last', 'email', 'phone', 'role', 'grades', 'subject_matter', 'school_name', 'school_district',
          'school_state', 'school_website', 'learning_format', 'is_approved', 'is_junk', 'is_waitlist'];
          
          fields.forEach(field => {
            console.log(field);
            this.schoolApproveForm.get(field).setValue(data['info'][field])
          });
        }
        if(data['info_ext']) {
          var fields = ['secondary_name_first', 'secondary_name_last', 'secondary_email', 'secondary_phone', 'secondary_role', 'school_address', 
          'school_grades', 'school_pop', 'school_demo', 'school_type',
          'school_title_1', 'us_reps', 'distribution_partners', 'notes'];
          fields.forEach(field => {
            console.log(field);
            this.schoolApproveForm.get(field).setValue(data['info_ext'][field])
          });
        }
        if(data['info_invite']) {
          // email_rep
          var fields = ['email_rep'];
          fields.forEach(field => {
            console.log(field);
            this.schoolApproveForm.get(field).setValue(data['info_invite'][field])
          });
        }
        if(data['events']) {
          this.expandedElement['events'] = data['events'];
        }
        if(data['forms']) {
          this.expandedElement['forms'] = data['forms'];
        }
        if(data['activity']) {
          this.expandedElement['activity'] = data['activity'];
        }



      },
      () => {
        this.loading = false;
      }
      )
  }

  startEmail(data) {
    window.location.href = "mailto:"+data.email+"?subject=Noggin%20Learning%20App";
  }

  formNote = "";
  submitFormNote() {
    this.loading = true;
    this.authService.postMessage(this.formNote, "form", this.formId).subscribe(
      (data) => {
        this.loading = false;
        this.formNote = "";
        //this.getForm(this.formId);
        this.getMessage("form", this.formId, this.formData.messages)
      },
      () => {
        this.loading = false;
      }
    );
  }

  getMessage(table, id, depot) {
    this.loading = true;
    this.authService.getMessage(table, id).subscribe(
      (data) => {
        this.loading = false;
        depot = data;
      },
      () => {
        this.loading = false;
      }
    );

  }

  submitNogginApproveForm(row) {
    this.loading = true;
    const val = this.nogginApproveForm.value;
    this.authService.postAdminNogginApproval(row.id, this.nogginSource, val.is_approved, val.unique_url_id).subscribe(
      (data) => {
        row.is_approved = val.is_approved;
        this.loading = false;
        this.selectElement(row);
        this.setNogginApprovedFilter(this.nogginApprovedFilter);
      },
      () => {
        this.loading = false;
      }
    );
  }

  schoolMarkAsJunk(row) {
    console.log(row);
    //this.submitSchoolApproveForm(row);

  }

  exportSchools() {
    this.authService.exportSchools().subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        //const url= window.URL.createObjectURL(blob);

        var name = 'Nick-Community-School-Partnerships.csv';
        var type = 'text/csv';

        if (data !== null && navigator.msSaveBlob) {
          return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        }

      },
      (err) => {
        alert(err);
        //this.accountLoaded = false;
      }
    );
  }

  willSendEmail() {
    var willSend = true;
    if(this.schoolApproveForm.controls.is_approved.value == null || this.schoolApproveForm.controls.is_approved.value == "") {
      willSend = false;
    } 
    if(this.schoolApproveForm.controls.is_approved.value == this.expandedElement['is_approved']) {
      willSend = false;
    }
    if(this.schoolApproveForm.controls.is_junk.value == "1") {
      willSend = false;
    }
    if(this.schoolApproveForm.controls.is_waitlist.value == "1") {
      willSend = false;
    }
    return willSend;
  }

  closeApproveForm() {
    this.expandedElement = null;
    this.schoolApproveForm.reset();
  }

  submitSchoolApproveForm(row) {
    this.loading = true;
    var val = this.schoolApproveForm.value;
    val.id = row.id;
    this.authService.postAdminSchoolApproval(val).subscribe(
      (data) => {
        row.is_approved = val.is_approved;
        this.loading = false;
        this.schoolApproveForm.reset();
        this.selectElement(row);
        this.setSchoolApprovedFilter(this.schoolApprovedFilter);
        this.getSchoolOverview();
      },
      () => {
        this.loading = false;
      }
    );
  }

  submitSchoolInvite(row) {
    // schoolInvite
    this.authService.postAdminSchoolInvite(row.id).subscribe(
      (data) => {
        //row.is_approved = val.is_approved;
        this.loading = false;
        //this.selectElement(row);
        this.setSchoolApprovedFilter(this.schoolApprovedFilter);
        this.getSchoolOverview();
      },
      () => {
        this.loading = false;
      }
    );
  }

  eblastId = null;
  eblastBody = 'preview';
  eblastDetails = null;
  viewEblast(id) {
    console.log(id)
    this.eblastId = id;
    this.eblastDetails = null;
    this.loading = true;
    this.authService.getAdminEblastDetails(id).subscribe(
      (data) => {
        this.loading = false;
        this.eblastDetails = data;
      },
      (err) => {
        this.loading = false;

      }
    )
  }

  saveEblast(id){
    var args = this.eblastDetails;
    console.log(id)
    this.loading = true;
    this.authService.postAdminEblast(args).subscribe(
      (data) => {
        this.loading = false;
        //this.eblastDetails = data;
      },
      (err) => {
        this.loading = false;

      }
    )

  }

  
  //postAdminEblastAddRecipient
  editEblastRecipients(id, action) {
    var args = { id: id, action: action };
    this.loading = true;
    this.authService.postAdminEblastEditRecipient(args).subscribe(
      (data) => {
        this.viewEblast(this.eblastId);
      },
      (err) => {
        this.loading = false;

      }
    )
  }

  saveEblastRecipients() {
    console.log(this.fruits)
    var args = {eblast_id: this.eblastId, user_arr: this.fruits};
    this.loading = true;
    this.authService.postAdminEblastAddRecipient(args).subscribe(
      (data) => {
        this.fruits = [];
        this.viewEblast(this.eblastId);

        //this.eblastDetails = data;
      },
      (err) => {
        this.loading = false;

      }
    )
  }

  sendBulkEblastRequest(id) {
    var count = this.eblastDetails['queue'];
    var r = confirm("Are you sure you want to bulk send this eblast to "+count+" recipient(s)? If so, press 'OK' and please don't leave this page while the eblast is being sent out (this could take a few minutes).");
    if (r == true) {
      this.eblastRemainingCount = this.eblastDetails['queue'];
      this.sendBulkEblast(id);
      //this.sendEblast(id)
    }
  }

  sendEblastRequest(id) {
    var count = this.eblastDetails['queue'];
    var r = confirm("Are you sure you want to send this eblast to "+count+" recipient(s)? If so, press 'OK' and please don't leave this page while the eblast is being sent out (this could take a few minutes).");
    if (r == true) {
      this.eblastRemainingCount = this.eblastDetails['queue'];
      this.sendEblast(id)
    }
  }

  calcEblastRemaining() {
    return (this.eblastRemainingCount/this.eblastDetails['queue']) * 100;
  }

  sendBulkEblast(id) {
    console.log("sending bulk!")
    var args = {id: id};
    this.loading = true;
    this.sendingEblast = true;
    this.authService.postAdminEblastSendAll(args).subscribe(
      (data) => {
          this.eblastRemainingCount = 0; //data['queue'];
          alert("Alert! Queue is " + data['queue']);
          this.sendingEblast = false;
          this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    )
  }

  sendingEblast = false;
  sendEblast(id) {
    console.log("sending!")
    var args = {id: id};
    this.loading = true;
    this.sendingEblast = true;
    this.authService.postAdminEblastSend(args).subscribe(
      (data) => {
        if(data['queue']) {
          if(data['queue'] != this.eblastRemainingCount) {
            this.eblastRemainingCount = data['queue'];
            this.sendEblast(id);
          } else {
            //this.eblastRemainingCount = data['queue'];
            alert("Alert! Queue is " + data['queue']);
            this.sendingEblast = false;
            this.loading = false;
          }
        } else if (data['queue'] == 0) {
          this.eblastRemainingCount = data['queue'];
          alert("Alert! Queue is " + data['queue']);
          this.sendingEblast = false;
          this.loading = false;

        }
      },
      (err) => {
        this.loading = false;
      }
    )
  }
    // sendEblast() {
  //   this.loading = true;
  //   this.authService.postEblast({}).subscribe(
  //     (data) => {
  //       if(data['queue']) {
  //         if(data['queue'] != this.eblastRemainingCount) {
  //           this.eblastRemainingCount = data['queue'];
  //           this.sendEblast();
  //         } else {
  //           //this.eblastRemainingCount = data['queue'];
  //           alert("Alert! Queue is " + data['queue']);
  //         }
  //       } else if (data['queue'] == 0) {
  //         this.eblastRemainingCount = data['queue'];
  //         alert("Alert! Queue is " + data['queue']);

  //       }
  //       this.loading = false;
  //     },
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  surveyId = null;
  surveyDetails = null;
  viewSurvey(id, autoSend = false) {
    console.log(id)
    this.surveyId = id;
    this.loading = true;
    this.authService.getAdminSurveyDetails(id).subscribe(
      (data) => {
        this.loading = false;
        this.surveyDetails = data;
        if(autoSend == true) {
          if(data['queue'] > 0) {
            this.sendSurveyInvitation(this.surveyId)
          }
        }
      },
      (err) => {
        this.loading = false;

      }
    )
  }

  rsvpId = null;
  rsvpDetails = null;
  viewRSVP(id) {
    console.log(id)
    this.rsvpId = id;
    this.loading = true;
    this.authService.getAdminRsvpDetails(id).subscribe(
      (data) => {
        this.loading = false;
        this.rsvpDetails = data;
      },
      (err) => {
        this.loading = false;

      }
    )
  }

  sendSurveyInvitation(id) {
    var args = {id: id};
    this.loading = true;
    this.authService.postSurveySendInvitation(args).subscribe(
      (data) => {
        this.loading = false;
        this.viewSurvey(this.surveyId, true);
        console.log(data);
        //this.surveyDetails = data;
      },
      (err) => {
        this.loading = false;
      }
    )
  }

  exportSurvey(id) {
    this.loading = true;
    this.authService.exportSurvey(id).subscribe(
      (data) => {
        this.loading = false;
        //this.viewSurvey(this.surveyId);
        const blob = new Blob([data], { type: 'text/csv' });
        //const url= window.URL.createObjectURL(blob);

        var name = 'Nick-Community-Events.csv';
        var type = 'text/csv';

        if (data !== null && navigator.msSaveBlob) {
          return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        }
      },
      (err) => {
        this.loading = false;

      }
    )
  }



  // WWDOP
  getSocial(id) {
    this.socialId = id;
    this.loading = true;
    this.socialData = null;
    this.authService.getAdminSocial(id).subscribe(
      (data) => {
        var d = data['data'];
        this.loading = false;
        this.socialData = d;
      },
      () => {
        this.loading = false;
      }
    );

  }

  sendChat() {
    this.loading = true;
    this.authService.postAdminChat(this.chatId, this.chatMsg).subscribe(
      (data) => {
        var id = this.chatId;
        this.chatMsg = "";
        this.getChat(null);
        this.getChat(id);
      }
    )
  }
  
  chatMsg = "";
  chatId = null;
  chatData = null;
  chatRefreshInterval = null;
  getChat(id = null, item = null) {
    this.loading = true;
    if(id != null) {
      this.chatId = id;
      this.chatData = null;
    }
    this.authService.getAdminChat(id).subscribe(
      (data) => {
        this.loading = false;
        if(id != null) {
          if(item) {
            item.is_read = '1';
          }
          this.loading = false;
          this.chatData = data['data'];

          setTimeout(function() {
            var a = document.getElementById("chat-messages");
            if(a) {
              a.scrollTop = a.scrollHeight;
            }
          }, 50);
        } else {
          this.adminData['chat'] = data['data'];
        }
      },
      () => {
        this.loading = false;
        //this.accountLoaded = false;
      }
    );
  }

  formId = null;
  formData = null;
  getForm(id, item = null) {
    this.formId = id;
    this.loading = true;
    this.formData = null;
    this.authService.getAdminForm(id).subscribe(
      (data) => {
        if(item) {
          item.is_read = '1';
        }
        var d = data['data'];
        this.loading = false;
        this.formData = d;
        this.formData['emailData'] = 'mailto:'+d.email+'?subject=Nick%20Community%20Reply&body=%0D%0A---%20Original%20Message%20---%0D%0A%0D%0A%0A'+encodeURI(d.form_data)
        if(this.formData['emailData'].length >= 2048) {
          this.formData['emailData'] = this.formData['emailData'].substring(0, 2040) + '...';
        }
      },
      () => {
        this.loading = false;
        //this.accountLoaded = false;
      }
    );

  }

  getFeedback(id) {
    this.feedbackId = id;
    this.loading = true;
    this.feedbackData = null;
    this.authService.getAdminFeedback(id).subscribe(
      (data) => {
        var d = data['data'];
        this.loading = false;
        this.feedbackData = d;
        this.feedbackData['emailData'] = 'mailto:'+d.email+'?subject=Nick%20Community%20Reply&body=%0D%0A---%20Original%20Message%20---%0D%0A%0D%0A'+encodeURI(d.body)
        if(this.feedbackData['emailData'].length >= 2048) {
          this.feedbackData['emailData'] = this.feedbackData['emailData'].substring(0, 2040) + '...';
        }
      },
      () => {
        this.loading = false;
        //this.accountLoaded = false;
      }
    );
  }

  sendInvite() {
    const val = this.inviteForm.value;
      if (val.email && val.name_first) { 
          this.loading = true;
          this.authService.mainPartnerInvite(val)
              .subscribe(
                  () => {
                    this.loading = false;
                    this.inviteForm.reset();
                    this.getOverview();
                  },
                  (err) => {
                    this.loading = false;
                    alert(err);
                  }
              );
      }
  }

  
  exportEblastRecipients(id) {
    this.authService.exportEblastRecipients(id).subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        //const url= window.URL.createObjectURL(blob);

        var name = 'Nick-Community-Eblast.csv';
        var type = 'text/csv';

        if (data !== null && navigator.msSaveBlob) {
          return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        }

      },
      (err) => {
        alert(err);
        //this.accountLoaded = false;
      }
    );
  }

  
  exportEvents() {
    this.authService.exportEvents().subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        //const url= window.URL.createObjectURL(blob);

        var name = 'Nick-Community-Events.csv';
        var type = 'text/csv';

        if (data !== null && navigator.msSaveBlob) {
          return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        }

      },
      (err) => {
        alert(err);
        //this.accountLoaded = false;
      }
    );
  }

  // Called on Filter change
  filterChange(filter, event) {
    //let filterValues = {}
    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    this.nogginDataSource.filter = JSON.stringify(this.filterValues)
    
    //this.nogginDataSource.filter = filterValue.trim().toLowerCase();
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      console.log(searchTerms);

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if(word == "all") {
                found = true;
              } else if(data[col] == null) {
                if(word == "null") {
                  found = true;
                }
              } else if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  //
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log("add");
    console.log(event);
    //this.fruits.push(event.value);

    // Add our fruit
    // if ((value || '').trim()) {
    //   this.fruits.push(value);
    // }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: object): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //console.log(event)
    this.fruits.push(event.option.value);
    this.fruitInput.nativeElement.value = null;
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: any): object[] {
    var filterValue;
    if(typeof(value) == "string") {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value["email"].toLowerCase();

    }
    // console.log(filterValue)

    return this.allFruits.filter(fruit => fruit['email'].toLowerCase().indexOf(filterValue) === 0);
    //return this.allFruits;
  }


  // Reset table filters
  // resetFilters() {
  //   this.filterValues = {}
  //   this.filterSelectObj.forEach((value, key) => {
  //     value.modelValue = undefined;
  //   })
  //   this.dataSource.filter = "";
  // }

}




function emailConditionallyRequiredValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  
  if (formControl.parent.get('is_approved').value == 1) {
    console.log(formControl.parent.get('is_approved').value);
    return Validators.required(formControl); 
  }
  return null;
}