import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AppToastService } from '../_helpers/toast.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements OnInit, OnDestroy {
  loading: boolean;
  event_slug: string;
  event_id: string;
  title: string;
  summary: string;
  description: string;
  link: any;
  rsvp_form: FormGroup;
  complete_message: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer,
    private toastService:AppToastService,
    public authService: AuthService,
    private fb:FormBuilder) 
    {
      this.renderer.setElementClass(document.body, 'theme-featured', true);
      this.rsvp_form = this.fb.group({
        FirstName: ['',this.noWhitespaceValidator],
        School: ['',this.noWhitespaceValidator],
        EmailAddress: ['',this.noWhitespaceValidator],
        Response: ['',this.noWhitespaceValidator]
      });
    }

    ngOnDestroy() {
      this.renderer.setElementClass(document.body, 'theme-featured', false);
    }

    ngOnInit() {
      this.loading = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      //if(params.get('survey_id')) {
        this.event_slug = params.get('event_slug');
        // if(params.get('survey_code')) {
          //this.survey_code = params.get('survey_code');
          this.verifyCode();
        // } else {
        //   this.loading = false;
        //   this.error_title = "Sorry, this link is invalid.";
        // }
      // } else {
      //   this.loading = false;
      //   this.error_title = "Sorry, this link is invalid.";
      // }
    });

    }

  verifyCode() {
    this.loading = true;

      this.authService.getCalendarEvent(this.event_slug).subscribe(
        (data) => {
          this.loading = false;
          this.event_id = data['id'];
          this.title = data['title'];
          this.summary = data['summary'];
          this.description = data['description'];
          this.link = data['link'];
          console.log(data)

          if(localStorage.getItem('rspv-'+this.event_slug)) {
            this.loading = false;
            this.complete_message = "Thank you for responding!";
          }

          //this.data = data;
        },
        () => {
          this.loading = false;
          //this.nogginStatus = "new"
        }
      );
  }

  submitRSVP() {
    if(this.rsvp_form.valid) {
      const val = this.rsvp_form.value;
      const data = {
        event_slug: this.event_slug,
        form_data: val
      }
      this.loading = true;
      this.authService.postCalendarEvent(data).subscribe(
        (data) => {
          this.loading = false;
          if(data['success']) {
            this.complete_message = data['message'];
            localStorage.setItem('rspv-'+this.event_slug, "yes");
          }
        },
        () => {
          this.loading = false;
        });
      } else {
        console.log("invalid")
      }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
