import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AppToastService } from '../_helpers/toast.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss']
})
export class FeaturedComponent implements OnInit, OnDestroy {

  constructor(
    private renderer: Renderer, 
    private toastService:AppToastService,
    public authService: AuthService) 
    {
    this.renderer.setElementClass(document.body, 'theme-featured', true)
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-featured', false);
  }

  data = [];

  ngOnInit() {
    this.authService.getFeaturedPrograms().subscribe(
      (data) => {
        this.data = data;
      },
      () => {
        //this.nogginStatus = "new"
      }
    );
  }

  getStaticFile(filename) {
    this.toastService.show("Downloading...",filename);
    this.authService.getFile({filename: filename, is_featured: true }, false).subscribe(
      (data: Blob) => {
        if(data.type == "text/html") {
          let uri = "./api/downloads/"+filename;
          console.log(uri);
          var link = document.createElement("a");
          link.download = filename;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          this.toastService.remove();
          //document.body.removeChild(link);
        } else {
          //console.log(data);
          FileSaver.saveAs(data, filename);
          this.toastService.remove();
        }
      });
  }

}
