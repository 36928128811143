import { Component, OnInit, Renderer } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import * as FileSaver from 'file-saver';
import { MetadataService } from '../_helpers/metadata.service';

@Component({
  selector: 'app-program-generic',
  templateUrl: './program-generic.component.html',
  styleUrls: ['./program-generic.component.scss']
})
export class ProgramGenericComponent implements OnInit {
  slug = null;
  id = null
  programData: any;
  loading = false;
  programTitleEncoded = "";

  constructor(
    public metadataService: MetadataService,
    route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private activeRoute: ActivatedRoute,
    private renderer: Renderer) {
    this.slug = route.snapshot.params.program;
    this.id = route.snapshot.params.id;
    console.log(route.snapshot.params)
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(routeParams => {
      this.slug = routeParams.program;
      this.id = routeParams.id;
      console.log(this.slug)
      this.setProgramSlug(this.slug);
      //this.loadUserDetail(routeParams.id);
    });
  }

  ngOnDestroy() {
    // this.renderer.setElementClass(document.body, 'theme-'+this.slug, false);
  }

  getFile(file) {
    let isSecure = false;
    this.loading = true;
    file.loading = true;
    if (file.filetype == "link1") {
      this.authService.getFile({ filename: file.filename }, isSecure).subscribe(
        (data: Blob) => {
          console.log(data);
          this.loading = false;
          //file.loading = false;
          var file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
      //window.open(file.filename);
      return false;

    } else {
      this.authService.getFile({ filename: file.filename }, isSecure).subscribe(
        (data: Blob) => {
          console.log(data)
          this.loading = false;
          file.loading = false;
          // let uri = file.filename as string;
          // if(!(uri.includes("http:") || uri.includes("https:") || uri.includes("/api/downloads/"))) {
          //   uri = "https://nickcommunity.com/api/downloads/"+uri;
          // }
          // console.log(uri);
          // var link = document.createElement("a");
          // link.href = uri;
          // link.target = "";
          // document.body.appendChild(link);
          // link.click();

        },
        (err) => {
          //alert(JSON.stringify(err));
          //console.log(JSON.stringify({ x: 5, y: 6 }));
          console.log(err)
          this.loading = false;
          file.loading = false;
          //this.accountLoaded = false;
        }
      );
    }
  }

  getProgram() {
    this.loading = true;

    //this.slug = name;
    this.authService.getProgram(this.slug).subscribe(
      (data) => {
        this.loading = false;
        this.programData = data;

        if (this.programData.resource_heading == null) {
          this.programData.resource_heading = "Resources"
        }

        this.programTitleEncoded = encodeURIComponent(this.programData["title"]);

        this.metadataService.updateParselyTitle(this.programData["title"] + " - Nickelodeon Community Partner Resources");
        this.metadataService.updateParselyLink(window.location.href);
        this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/" + this.programData["logo"]);

        this.programData["resource_extras"].forEach(file => {
          //this.getFile(item);
          let uri = file.filename as string;
          file.filepath = file.filename;
          // if (!(uri.includes("http:") || uri.includes("https:") || uri.includes("/api/downloads/"))) {
          if (!(uri.includes("http:") || uri.includes("https:"))) {
            // file.filepath = "https://nickcommunity.com/api/downloads/" + uri;
            file.filepath = "https://nickcommunity.com" + uri;
          }
        });
      },
      () => {
        this.loading = false;
        console.log("error")
        this.router.navigateByUrl('/');
        //this.accountLoaded = false;
      }
    );
  }

  setProgramSlug(id) {
    if (this.slug != null) {
      //this.renderer.setElementClass(document.body, 'theme-'+this.slug, false);
      this.slug = null;
      this.programData = {};
    }
    this.slug = id
    localStorage.setItem('NickCommunityProgram', this.slug);
    //this.renderer.setElementClass(document.body, 'theme-'+this.slug, true);
    this.getProgram();
  }



}