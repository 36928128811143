import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { MetadataService } from '../_helpers/metadata.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    public metadataService: MetadataService,
    private renderer: Renderer, 
    public authService: AuthService) {
    this.renderer.setElementClass(document.body, 'theme-evergreen', true);
  }
  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }

  homeStatus:any[];

  ngOnInit() {

    this.metadataService.updateParselyTitle("Nickelodeon Community Partner Resources");
    this.metadataService.updateParselyLink(window.location.href);
    this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/Nickelodeon_CPR_logo_white.svg");

    this.authService.getHomeStatus().subscribe(
      (data) => {
        this.homeStatus = data;
      },
      () => {
        //this.nogginStatus = "new"
      }
    );
  }

}
