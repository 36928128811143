import { Component, OnInit, OnDestroy, Renderer, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap  } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { AppToastService } from '../_helpers/toast.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { MetadataService } from '../_helpers/metadata.service';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit,OnDestroy {

  //eventTabs

  slug:string = null;
  nogginKey:string = null;
  showEventForm:boolean = true;
  showInvites:boolean = false;
  showSocial:boolean = false;
  showFeedback:boolean = false;
  programData:object = { about: null };
  loading:boolean = false;

  socialHashtags = ["#WorldwideDayofPlay", "#WWDoPisAnyday", "#Nickelodeon", "#GetUpGetOutandGoPlay"];

  formEvent:FormGroup;
  formSocial:FormGroup;
  formFeedback:FormGroup;
  formNogginRequest:FormGroup;
  formSchool:FormGroup;
  formTalent:FormGroup;
  formCareer:FormGroup;
  formBTBRequest:FormGroup;

  nogginArr = ["new", "invited", "optin", "requested"];
  nogginStatus = "";

  schoolStatus = "";
  schoolInviteData = null;
  schoolGrades = ["Preschool", "Kindergarten", "Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8"]
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  showBTBRequest = false;
  showBTBSuccess = false;

  koty2021 = [
    {
      name: "Social & Environmental",
      arr: [
        {
          photo: "KOTY-Rebekah.jpg",
          name: "REBEKAH BRUESEHOFF",
          bio: "Rebekah is an outspoken transgender activist who works to strengthen support systems for other transgender and LGBTQ+ youth.Rebekah's own transition, and the support she received, inspired her to provide other kids with a welcoming space to live their truth. She wants to show the world that transgender kids are just like other kids, and like other kids, they deserve our love and protection."
        },
        {
          photo: "KOTY-Chloe.jpg",
          name: "CHLOE MEI ESPINOSA",
          bio: "Chloe Mei Espinosa’s interest in scuba diving has inspired her work to protect the oceans by advocating for the reduction of plastic straws worldwide. Chloe’s innovative approach and dedication to spreading awareness shows the great impact individuals of all ages can have on their communities."
        },
        {
          photo: "KOTY-Ryan.jpg",
          name: "RYAN HICKMAN",
          bio: "Ryan is a prolific recycler who is spreading his environmentalism to fans all over the world. He's a recycling rockstar, breathing new life into an otherwise familiar environmental practice. Ryan has been on a mission to clean up the planet since he was 3 years old. His passion and commitment has only grown over the years."
        },
        {
          photo: "KOTY-Hannah.jpg",
          name: "HANNAH JACKSON",
          bio: "Hannah Jackson is an outspoken advocate for criminal justice reform who has experienced the cruelties of the prison system firsthand. As someone who grew up with an incarcerated father, Hannah knows how profoundly the prison system punishes families, and is passionate about protecting other kids from that pain."
        },
        {
          photo: "KOTY-Elijah.jpg",
          name: "ELIJAH LEE",
          bio: "Elijah is a natural-born leader and orator devoted to combating child abuse and other social injustices. His charisma, passion, and personality set him apart. Young activist Elijah Lee is seeking justice for young people by raising awareness to the issue of child abuse. He inspires others to his cause by sheer willpower, channeling personal heroes like Dr. Martin Luther King Jr., Angela Davis, and John Lewis."
        },
        {
          photo: "KOTY-Bellen.jpg",
          name: "BELLEN WOODARD",
          bio: "Bellen began The More than Peach Project in the Spring of 2019 after developing a solution to successfully change the language of her peers in her own school community to no longer view the peach crayon as the single skin color crayon. Bellen Woodard is the world’s first Crayon Activist highlighting an important diversity issue through the eyes and experiences of a child."
        },
        {
          photo: "KOTY-Jack.jpg",
          name: "JACK DALTON",
          bio: "Jack Dalton is a 10-year-old working in a variety of ways to educate kids on how important it is for them to be invested in their environment and the world. His optimism and spunk make everyone who sees or meets him care about the environment. Jack has spent the last year working to get orangutans off the endangered species list."
        },
      ]
    },
    {
      name: "Education & Innovation",
      arr: [
        {
          photo: "KOTY-Samaira.jpg",
          name: "SAMAIRA MEHTA",
          bio: "Samaira is the 12-year-old founder of \"Yes, 1 Billion Kids Can Code\" and CEO of a board game company called \"CoderBunnyz,\" which teaches basic coding concepts. With her various initiatives, Samaira brings an entrepreneurial spirit and drive unique for someone her age. Her goal is to get 1 billion kids into coding by the time she graduates from college around 2030."
        },
        {
          photo: "KOTY-Sophia.jpg",
          name: "SOPHIA SCOTT",
          bio: "Sophia Scott is working to uplift underserved children around the world who have been academically impacted by COVID-19.In 202 she Founded a nonprofit organization called Quaranteens during the height of the COVID-19 pandemic in order to provide completely free tutoring services to students around the world in the wake of school closures and summer school cancellations due to the pandemic."
        },
        {
          photo: "KOTY-Anna.jpg",
          name: "ANNA MILLER",
          bio: "Anna is a 12-year-old born with Osteogenesis Imperfecta, a rare genetic disorder that causes bones to be very fragile. She has to use crutches or a wheelchair to move around. Anna's passions are for coding, app development, education, and in addition she is an accessibility activist."
        },
        {
          photo: "KOTY-Ronak.jpg",
          name: "RONAK SUCHINDRA",
          bio: "Ronak Suchindra is a 13-year old who is dedicated to helping his community. His love for teaching others led him to create Kids Connect, which is an online platform where older kids can teach younger kids. He wants to inspire and motivate youth volunteers to be the ambassadors in building this new era of education."
        }
      ]
    },
    {
      name: "Health & Disability",
      arr: [
        {
          photo: "KOTY-Ian.jpg",
          name: "IAN MCKENNA",
          bio: "Ian is a 15-year-old committed to hunger relief. Ian is making an impact as an activist and fundraiser, while also he is planting and harvesting food for his community of Austin, TX."
        },
        {
          photo: "KOTY-Caleb.jpg",
          name: "CALEB OH",
          bio: "After being told he couldn’t volunteer because he was too young, Caleb decided to launch his own non-profit, called “Kid Changemakers.” Through Kid Changemakes, Caleb created an organization that allows other kids to volunteer in the community. He started off by recruiting kids from his neighborhood to make bagged lunches for a local homeless shelter, and then partnered with shelters, schools and a local church to conduct food and toiletry drives."
        },
        {
          photo: "KOTY-ZaNia.jpg",
          name: "ZA’NIA STINSON",
          bio: "Za’nia Stinson has provided thousands of food and toiletry items to women and children staying at a local shelter and to people living on the streets. Hungry, homeless women and children, people living on the street and senior citizens are issues at the heart of this little girl. Sadly, she knew hunger and homelessness."
        }
      ]
    },
    {
      name: "Giving Back to Communities",
      arr: [
        {
          photo: "KOTY-Khloe.jpg",
          name: "KHLOE THOMPSON",
          bio: "Khloe Thompson was just 8 years old when she was introduced to the issue of homelessness in her community. After seeing women and families living on the street in her native Irvine, California, she knew she wanted to help. Khloe is passionate about inspiring other kids her age to create change, proving that there is no age limit on the impact an individual can have in their community and around the world."
        },
        {
          photo: "KOTY-Dylan.jpg",
          name: "DYLAN CAPSHAW",
          bio: "Dylan is an extremely motivated 14-year-old with a focus on making a difference in this world, whether it’s for an animal or a human being. He wants to be a voice for the animals and help protect our frontline workers. Dylan has always been a passionate wildlife activist, but with the scare of COVID-19, Dylan immediately started printing 3D masks."
        },
        {
          photo: "KOTY-Jordan.jpg",
          name: "JORDAN REEVES",
          bio: "Jordan was born with a left arm that stopped growing just below her elbow. Her limb difference has never slowed her down, in fact, it drove her to become a designer and a source of inspiration for kids with limb and physical differences."
        },
        {
          photo: "KOTY-Gitanjali.jpg",
          name: "GITANJALI RAO",
          bio: "Gitanjali is a one-in-a-million kid. She’s an inventor and scientist who centers her work in empathy, advocating for a people-centered approach to invention, and problemsolving."
        }
      ]
    },
    {
      name: "The Arts",
      arr: [
        {
          photo: "KOTY-Keedron.jpg",
          name: "KEEDRON BRYANT",
          bio: "Keedron’s vocal gifts became known to the world in the wake of George Floyd’s death. Keedron’s impassioned plea for, “I Just Want to Live” became an anthem for the Black Lives Matter movement."
        },
        {
          photo: "KOTY-Tyler.jpg",
          name: "TYLER GORDON",
          bio: "Tyler was born deaf and learned to speak when he was 5. After receiving surgery at 6 to restore hearing in one ear, Tyler developed a stutter. At the young age of 14, Tyler has experienced a lifetime of struggle and bullying, but along the way, he’s found courage and his voice through his art."
        }
      ]
    }
  ]

  constructor(
    public metadataService: MetadataService,
    private sanitizer:DomSanitizer,
    private toastService:AppToastService,
    private fb:FormBuilder,
    private renderer: Renderer,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private cd: ChangeDetectorRef) {
      
      this.nogginKey = this.route.snapshot.paramMap.get("key");

      this.formNogginRequest = this.fb.group({
        name: ['',Validators.required],
        email: ['',Validators.required],
        organization: ['',Validators.required],
        message: ['',Validators.required]
      });

      this.formSchool = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        email: ['',this.noWhitespaceValidator],
        phone: [''],
        role: [''],
        grades: this.fb.array(this.schoolGrades.map(s => this.fb.control(false)), [ Validators.required ]),
        subject_matter: [''],
        school_name: ['',this.noWhitespaceValidator],
        school_district: ['',this.noWhitespaceValidator],
        school_state: ['',this.noWhitespaceValidator],
        school_website: [''],
        learning_format: ['',Validators.required],
        requires_approval: ['',Validators.required],
        interest: [''],
        school_events: new FormArray([]),
        event_name: [''],
        event_month: [''],
        event_year: [''],
        requires_approval_full_name: [''],
        requires_approval_title: [''],
        requires_approval_email: [''],
        requires_approval_phone: ['']
      });

      this.formTalent = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        title: [''],
        phone: [''],
        email: [''],
        school_name: ['',this.noWhitespaceValidator],
        city: [''],
        state: [''],
        grade_levels: [''],
        event_start_date: [''],
        event_info: ['']

      })


      this.formCareer = this.fb.group({
        name_first: ['',this.noWhitespaceValidator],
        name_last: ['',this.noWhitespaceValidator],
        title: [''],
        phone: [''],
        email: [''],
        school_name: ['',this.noWhitespaceValidator],
        city: [''],
        state: [''],
        grade_levels: [''],
        career_topics: [''],
        career_day: ['']

      })

      this.formBTBRequest = this.fb.group({
        name: ['',Validators.required],
        school: ['',Validators.required],
        address: ['',Validators.required],
        quantity_requested: ['',Validators.required],
        date_requested: ['',Validators.required],
        used_for_event: [''],
        event_name: ['']

      })

      this.formEvent = this.fb.group({
        date_event: ['',Validators.required],
        organization_name: ['',Validators.required],
        contact_name: ['',Validators.required],
        address_city: ['',Validators.required],
        address_state: ['',Validators.required],
        attendees: ['',Validators.required],
        about: ['',Validators.required]
      });

      // , [this.requiredFileType(['png','jpg','jpeg'])]

      this.formSocial = this.fb.group({
        date_event: [''],
        address_city: [''],
        address_state: [''],
        event_id: [null],
        about: ['',Validators.required],
        photo1: [null],
        photo2: [null],
        photo3: [null],
        photo4: [null],
        has_permission: [''],
        can_reshare: ['']
      })

      this.formFeedback = this.fb.group({
        subject: ['WWDoP Feedback'],
        body: ['',Validators.required],
        photo1: [null],
        photo2: [null],
        photo3: [null],
        photo4: [null],
        has_permission: ['']
      })

        

      if(route.snapshot.data['id']) {
        this.setProgramSlug(route.snapshot.data['id']);
      }

   }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if(params.get('id')) {
        this.setProgramSlug(params.get('id'));
      }
      if(params.get('key')) {
        this.nogginKey = params.get('key');
        //this.setProgramSlug(params.get('key'));
      }
    });
  }

  getNogginStatus() {
    if(this.authService.isLoggedIn()) {
      this.nogginStatus = "loading"
      this.authService.getNogginStatus().subscribe(
        (data) => {
          this.nogginStatus = data['status'];
          localStorage.setItem("NickCommunityNoggin", data['status']);
        },
        () => {
          this.nogginStatus = "new"
        }
      );
    }
  }
  
  getSchoolStatus() {
    this.schoolStatus = 'approved';
    /*if(this.authService.isLoggedIn()) {
      //this.schoolStatus = "loading"
      this.loading = true;

      this.authService.getSchoolStatus().subscribe(
        (data) => {
          this.loading = false;
          this.schoolStatus = data['status'];
          localStorage.setItem("NickCommunitySchool", data['status']);

          this.formSchool.get('email').setValue(data['email'])
          this.formSchool.get('name_first').setValue(data['name_first'])
          this.formSchool.get('name_last').setValue(data['name_last'])
          this.formSchool.get('school_name').setValue(data['school_name']);

          if(this.schoolStatus == "new") {
            this.getSchoolInvitation();
          }

        },
        () => {
          this.loading = false;
          this.schoolStatus = "new"
          this.getSchoolInvitation();
        }
      );
    } else {
      if(localStorage.getItem("NickCommunitySchool")) {
        this.schoolStatus = localStorage.getItem("NickCommunitySchool");
        if(this.schoolStatus == "new") {
          this.getSchoolInvitation();
        }
      } else {
        this.schoolStatus = "new"
        this.getSchoolInvitation();
      }
    }*/
  }

  



  clearCookies() {
    localStorage.clear();
    location.reload();
  }



  // setNogginStatus(status) {
  //   this.nogginStatus = "loading"
  //   setTimeout(() => {
  //     this.nogginStatus = status
  //  }, 1500);
  // }

  submitBTBRequest() {
    this.loading = true;
    const val = this.formBTBRequest.value;
    const data = {
      form_id: 1,
      form_name: "Beyond the Backpack",
      program_id: 5,
      form_data: val
    }
    this.authService.postSubmitForm(data).subscribe(
    (data) => {
      this.loading = false;
      this.showBTBSuccess = true;
      this.showBTBRequest = false;
    },
    () => {
      this.loading = false;
    });
  }

  showTalentSuccess = false;
  submitTalentRequest() {
    this.loading = true;
    const val = this.formTalent.value;
    const data = {
      form_id: 3,
      form_name: "TFG: Nickelodeon Talent Request",
      program_id: 5,
      form_data: val
    }
    this.authService.postSubmitForm(data).subscribe(
    (data) => {
      this.loading = false;
      this.showTalentSuccess = true;
      this.formTalent.reset();
    },
    () => {
      this.loading = false;
    });
  }
  submitCareerRequest() {
    this.loading = true;
    const val = this.formCareer.value;
    const data = {
      form_id: 2,
      form_name: "TFG: Career Day Support Request",
      program_id: 5,
      form_data: val
    }
    this.authService.postSubmitForm(data).subscribe(
    (data) => {
      this.loading = false;
      this.showTalentSuccess = true;
      this.formCareer.reset();
    },
    () => {
      this.loading = false;
    });
  }
  
  

  submitNogginRequest() {
    this.nogginStatus = "loading"
    const val = this.formNogginRequest.value;
    this.authService.postNogginRequest(val).subscribe(
      (data) => {
        this.nogginStatus = data['status'];
        localStorage.setItem("NickCommunityNoggin", "requested");
      },
      () => {
        this.nogginStatus = "new"
      }
    );
  }

  submitNogginOptIn() {
    this.nogginStatus = "loading"
    this.authService.postNogginOptIn({key: this.nogginKey}).subscribe(
      (data) => {
        this.nogginStatus = data['status'];
        localStorage.setItem("NickCommunityNoggin", "optin");
      },
      () => {
        this.nogginStatus = "invite"
      }
    );

  }

  subform = "";
  setProgramSlug(id) {
    if(this.slug != null) {
      this.renderer.setElementClass(document.body, 'theme-'+this.slug, false);
      this.slug = null;
      this.programData = {};
      }
      this.slug = id
      localStorage.setItem('NickCommunityProgram', this.slug);
      this.renderer.setElementClass(document.body, 'theme-'+this.slug, true);
      this.getProgram();

      if(this.slug == "schoolpartnership") {
        var today = new Date();
        this.formSchool.get('event_year').setValue(today.getFullYear());
        this.formSchool.get('event_month').setValue(this.months[today.getMonth()]);
        this.getSchoolStatus();       
      }

      if(this.slug == "talentforgood") {
        this.subform = 'careerday';
      }

      if(this.slug == "noggin") {
        this.getNogginStatus();

        if(this.nogginKey) {
          // TODO authenticate user
          this.submitNogginOptIn();

        } else {
          if(localStorage.getItem("NickCommunityNoggin")) {
            this.nogginStatus = localStorage.getItem("NickCommunityNoggin");
          } else {
            this.nogginStatus = "new";
          }
        }
      }

  }

  getSchoolInvitation() {
    if(this.nogginKey) {
      this.loading = true;
      this.authService.getSchoolInvite(this.nogginKey).subscribe(
        (data) => {
          this.schoolStatus = data['status'];
          this.schoolInviteData = data['data'];
          this.formSchool.get('name_first').setValue(data['data']['name_first']);
          this.formSchool.get('name_last').setValue(data['data']['name_last']);
          this.formSchool.get('email').setValue(data['data']['email']);
          this.formSchool.get('role').setValue(data['data']['role'].toLowerCase());
          this.formSchool.get('school_name').setValue(data['data']['school_name']);
          this.formSchool.get('school_district').setValue(data['data']['school_district']);
          this.formSchool.get('school_state').setValue(data['data']['school_state']);
          this.formSchool.get('school_name').disable();
          this.formSchool.get('requires_approval').setValue("0");
          this.loading = false;
        });
    }
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-'+this.slug, false);
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getStaticFile(filename) {
    this.toastService.show("Downloading...",filename);
    this.authService.getFile({filename: filename}).subscribe(
      (data: Blob) => {
        if(data.type == "text/html") {
          let uri = "./api/downloads/"+filename;
          console.log(uri);
          var link = document.createElement("a");
          link.download = filename;
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          this.toastService.remove();
          //document.body.removeChild(link);
        } else {
          //console.log(data);
          FileSaver.saveAs(data, filename);
          this.toastService.remove();
        }
      });
  }

  getFile(file, isSecure = true) {
    if(file.require_login == 0) {
      isSecure = false;
    }
    this.loading = true;
    file.loading = true;
    if(file.filetype == "link") {
      this.authService.getFile({filename: file.filename}, isSecure).subscribe(
        (data: Blob) => {
          console.log(data);
        });
      this.loading = false;
      file.loading = false;
      window.open(file.filename);
      return false;

    } else {
      this.authService.getFile({filename: file.filename}, isSecure).subscribe(
        (data: Blob) => {
          console.log(data)
          this.loading = false;
          file.loading = false;
          if(data.type == "text/html") {
            let uri = "/api/downloads/"+file.filename;
            console.log(uri);
            var link = document.createElement("a");
            link.download = file.filename;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            //document.body.removeChild(link);
          } else {
            FileSaver.saveAs(data, file.filename);
          }
          //file.data = this.sanitize(data);
          //window.open(data);
        },
        (err) => {
          //alert(err);
          console.log(err)
          this.loading = false;
          file.loading = false;
          //this.accountLoaded = false;
        }
      );
    }
  }

  changeSocialEvent(event) {
    var id = this.formSocial.get('event_id').value;

    var result = this.programData['events'].filter(obj => {
      return obj.id === id
    })
    if(result.length > 0) {
      result = result[0];

      this.formSocial.patchValue({
        date_event: result.date_event,
        address_city: result.address_city,
        address_state: result.address_state,
      });
    } else {
      this.formSocial.reset();
    }
  }

  copyClipboard(tag) {

  }

  hasEventId() {
    var id = this.formSocial.get('event_id').value;
    //console.log(id);
    return id != null;
  }

  getProgram() {
    this.loading = true;
    
    //this.slug = name;
    this.authService.getProgram(this.slug).subscribe(
      (data) => {
        this.loading = false;
        this.programData = data;

        this.metadataService.updateParselyTitle(this.programData["title"] + " - Nickelodeon Community Partner Resources");
        this.metadataService.updateParselyLink(window.location.href);
        this.metadataService.updateParselyImageUrl(window.location.origin + "/assets/" + this.programData["logo"]);

        if(data['events'].length > 0) {
          this.showEventForm = false;
        }

        this.programData["resource_extras"].forEach(item => {
          //this.getFile(item);
        });

        if(this.slug == 'schoolpartnership') {

          // group by grade
          var grade_groups = this.programData['resource_extras'].reduce(function(obj,item){
            var key = "";

            // if(item.attribute_2 == null) {
            //   key = "TBD";
            // } else if(item.attribute_2 < 3) {
            //   key = "Preschool thru Grade 2";
            // } else if (item.attribute_2 >= 3) {
            //   key = "Grade 3 thru Grade 5";
            // } else {
            //   key = "TBD";
            // }
            obj[key] = obj[key] || [];
            // key['grade_range'] = key;
            obj[key].push(item);
            return obj;
          }, {});
          // sort
          console.log(grade_groups)

          this.programData['resource_grades'] = grade_groups;
          this.filterGrades(null);
          //this.programData['resource_grades_filtered'] = grade_groups;

          //console.log(this.programData);
        }
        
        //this.getFile(this.programData["resource_playbook"]);
        //this.getFile(this.programData["resource_logo"]);

      },
      () => {
        this.loading = false;
        console.log("error")
        this.router.navigateByUrl('/');
        //this.accountLoaded = false;
      }
    );
  }

  gradeFilter = null;
  filterGrades(grade) {
    //this.eventTabs.select('tab2')
    this.programData['resource_grades_filtered'] = [];
    if(grade != null) {
      grade = parseInt(grade);
    }
    this.gradeFilter = grade;
    var arr = [];
    var len = this.programData['resource_grades'][""].length;
    for(var i = 0; i < len; i++) {
      var element = this.programData['resource_grades'][""][i];
      if(grade == null || (parseInt(element.attribute_2) <= grade && parseInt(element.attribute_3) >= grade)) {
        arr.push(element)
      }
    };
    this.programData['resource_grades_filtered'][""] = arr;
  }

  submitEvent() {
    this.loading = true;
    const val = this.formEvent.value;
    this.authService.saveEvent(val)
        .subscribe(
            () => {
              this.loading = false;
              //this.showEventForm = false;
              this.getProgram();
            }
        );
  }

  editEvent() {

  }

  deleteEvent(id) {
    this.loading = true;
    this.authService.deleteEvent(id)
        .subscribe(
            () => {
              this.loading = false;
              this.getProgram();
            },
            (err) => {
              this.toastService.show("Error",err);
              this.loading = false;
            }
        );
    
  }

  submitSocial() {
    this.loading = true;
    const val = this.formSocial.value;
    this.authService.saveSocial(val)
        .subscribe(
            () => {
              this.loading = false;
              this.formSocial.reset();
              alert("Thank you for submitting your social media post!");
              //this.showEventForm = false;
              //this.getProgram();
            },
            (err) => {
              this.loading = false;
              alert(err);
            }
        );
  }

  submitFeedback() {
    this.loading = true;
    const val = this.formFeedback.value;
    this.authService.saveFeedback(val)
        .subscribe(
            () => {
              this.loading = false;
              this.formFeedback.reset();
              alert("Thank you for submitting your feedback! If you submit a question we'll get back to you soon.");
              //this.showEventForm = false;
              //this.getProgram();
            },
            (err) => {
              this.loading = false;
              alert(err);
            }
        );
  }

  onFileChange(event, form:FormGroup, field) {
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const extension = file.name.split('.')[1].toLowerCase();
      const valid_extensions = ['png', 'jpg', 'jpeg'];
      if(valid_extensions.includes(extension)) {
        reader.readAsDataURL(file);
    
        reader.onload = () => {
          form.patchValue({
            [field]: reader.result
          });
          // need to run CD since file load runs outside of zone
          this.cd.markForCheck();
        };
      } else {
        form.patchValue({
          [field]: null
        });
      }
    }
  }

  requiredFileType( types ) {
    return function (control: FormControl) {
      const file = control.value;
      //console.log(file);
      console.log(types);
      if ( file ) {
        //const extension = file.name.split('.')[1].toLowerCase();
        var is_valid = false;
        types.forEach(type => {
          //if ( type.toLowerCase() !== extension.toLowerCase() ) {
          if(file.includes("data:image/" + type)) {
            is_valid = true;
          }
        });
        console.log(is_valid);
        return {
          requiredFileType: !is_valid
        }
      }
      return false;
    };
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

}