import { Component, OnInit, OnDestroy, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  type: String = "login";
  error: String = null;
  loading: Boolean = false;

  constructor(private fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private renderer: Renderer) {
    this.renderer.setElementClass(document.body, 'theme-evergreen', true);

    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    if(this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/account');
    }
  }

  ngOnDestroy() {
    this.renderer.setElementClass(document.body, 'theme-evergreen', false);
  }



  login() {
    const val = this.form.value;
    if (val.email && val.password) {
      this.loading = true;
      this.error = null;
      this.authService.login(val.email, val.password)
        .subscribe(
          () => {
            this.loading = false;
            this.router.navigateByUrl('/program/dayofplay');
          },
          (msg) => {
            this.loading = false;
            if (msg) {
              this.error = msg;
            }
          }
        );
    } else {
      this.error = "Email and password required."
    }
  }

}
