import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterColumn'
})
export class FilterColumnPipe implements PipeTransform {

  // transform(items: any[], filter: Object): any {
  //   return items.filter(item => item.is_approved == filter);
  // }

  transform(items: any[], arg_key, arg_value): any {
    return items.filter(item => item[arg_key] == arg_value);
  }

}
